import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentCanceledService } from '../../../services/other-services/appointment-canceled/appointment-canceled.service';
import { AppointmentsService } from '../../../services/api/appointments/appointments.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { CancellationsService } from '../../../services/api/cancellations/cancellations.service';
import { CancellationReason } from '../../../models/interfaces/requests/ICancellationRequest';
import { IAppointmentResponse } from '../../../models/interfaces/responses/IAppointmentResponse';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';

@Component({
  selector: 'app-modal-cancelation-register',
  templateUrl: './modal-cancelation-register.component.html',
  styleUrl: './modal-cancelation-register.component.scss',
})
export class ModalCancelationRegisterComponent {
  @Input() appointment: IAppointmentResponse = {} as IAppointmentResponse;
  fb = inject(NonNullableFormBuilder);
  cookie = inject(CookieService);
  employees: IEmployeeResponse[] = [];
  cancelationForms = this.fb.group({
    storeId: this.fb.control(this.cookie.get('storeId')),
    appointmentId: this.fb.control('', {
      validators: [Validators.required],
    }),
    responsable: this.fb.control('', {
      validators: [Validators.required],
    }),
    reason: this.fb.control(0, {
      validators: [Validators.required],
    }),
    comment: this.fb.control(''),
  });
  reasons: any[] = [
    { id: 1, name: 'Emergencia' },
    { id: 2, name: 'Doença' },
    { id: 3, name: 'Preço' },
    { id: 4, name: 'Serviço ruim' },
    { id: 5, name: 'Trânsito' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private cancelationService: CancellationsService,
    private employeesService: EmployeesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  ngOnInit(): void {
    console.log('tetsgfgsdfh',this.appointment)
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.employees = employees;
    });
    this.cancelationForms.get('appointmentId')?.setValue(this.appointment.id);
    this.cancelationForms.get('responsable')?.setValue(this.appointment.employeeId);
  }

  onSubmit() {
    console.log(this.cancelationForms.getRawValue());
    this.cancelationService
      .postCancellation(this.cancelationForms.getRawValue())
      .subscribe((response) => {
        this.toastr.success('Agendamento cancelado!');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success');
      });
    
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.cancelationForms.valid) {
      this.onSubmit();
    }
  }
}

<div class="container-fluid pb-3 px-3 mt-4" style="max-height: 88vh;overflow-y: auto;">
  <div class="row mb-5">
    <div class="col-12">
      <div class="dropdown">
        <button class="btn btn-lg btn-light border dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-funnel"></i> Selecione o funcionário
        </button>
        <ul class="dropdown-menu dropdown-menu-end p-3 mt-2" aria-labelledby="filterDropdown">
          <div class="filter-header p-2">
            <input type="text" class="form-control form-control-lg" placeholder="Search" [(ngModel)]="searchText">
          </div>
          <div class="filter-list">
            <div *ngFor="let user of filteredUsers()" class="filter-item d-flex align-items-center p-2 gap-2" (click)="selectUser(user)">
              <input type="radio" class="form-check-input" name="userRadio" [(ngModel)]="selectedUser" [value]="user">
              <!-- <img [src]="user.avatar" class="rounded-circle mx-2" width="30" height="30" alt="{{user.name}}"> -->
              <span>{{ user.name }}</span>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3 col-sm-6 mb-4" *ngFor="let card of cards">
      <div class="card text-center">
        <div class="card-body">
          <div class="icon mb-2">
            <i [class]="card.icon"></i>
          </div>
          <h3 class="card-title">{{ card.title }}</h3>
          <p class="card-text">{{ card.value | currency }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">
          Worldwide Sales
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body">
          <canvas
            baseChart
            [data]="barChartData"
            [options]="barChartOptions"
            [type]="barChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Sales & Revenue
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body">
          <canvas
            baseChart
            [data]="lineChartData"
            [options]="lineChartOptions"
            [type]="lineChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-6 mb-4">
      <div class="card">
        <div class="card-header">Atendimentos por funcionarios</div>
        <div class="card-body">
          <canvas
            baseChart
            [data]="pieChartData"
            [options]="pieChartOptions"
            [type]="pieChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Sales & Revenue
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body">
          <canvas
            baseChart
            [data]="lineChartNoFillData"
            [options]="lineChartNoFillOptions"
            [type]="lineChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Top 5 melhores funcionários
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body" style="overflow:auto;">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Invoice</th>
                <th scope="col">Customer</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of salesData">
                <td>{{ sale.date }}</td>
                <td>{{ sale.invoice }}</td>
                <td>{{ sale.customer }}</td>
                <td>{{ sale.amount | currency }}</td>
                <td>{{ sale.status }}</td>
                <td><button class="btn btn-primary btn-lg">Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Top 5 procedimentos mais procurados
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body" style="overflow:auto;">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Invoice</th>
                <th scope="col">Customer</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of salesData">
                <td>{{ sale.date }}</td>
                <td>{{ sale.invoice }}</td>
                <td>{{ sale.customer }}</td>
                <td>{{ sale.amount | currency }}</td>
                <td>{{ sale.status }}</td>
                <td><button class="btn btn-primary btn-lg">Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Top 5 avaliações de funcionários
          <a href="#" class="float-right">Show All</a>
        </div>
        <div class="card-body" style="overflow:auto;">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Invoice</th>
                <th scope="col">Customer</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of salesData">
                <td>{{ sale.date }}</td>
                <td>{{ sale.invoice }}</td>
                <td>{{ sale.customer }}</td>
                <td>{{ sale.amount | currency }}</td>
                <td>{{ sale.status }}</td>
                <td><button class="btn btn-primary btn-lg">Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-header">
  <h2 class="modal-title">Cadastrar agendamento</h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div class="modal-body-content">
    <div class="content-section">
      <div class="accordion-body">
        <form
          class="d-flex flex-column gap-3"
          [formGroup]="appointmentForms"
          (ngSubmit)="onSubmit()"
        >
          <!-- <div class="d-flex justify-content-start gap-2 mb-3">
            <button type="button" class="btn btn-lg btn-outline-primary" (click)="openCustomerRegisterModal()">
              <i class="fas fa-user-plus"></i> Adicionar Cliente
            </button>
            <button type="button" class="btn btn-lg btn-outline-primary" (click)="openProductRegisterModal()">
              <i class="fas fa-box-open"></i> Adicionar Produto
            </button>
            <button type="button" class="btn btn-lg btn-outline-primary" (click)="openServiceRegisterModal()">
              <i class="fas fa-concierge-bell"></i> Adicionar Serviço
            </button>
          </div> -->
          <div class="form-group">
            <label for="typeahead-focus">Funcionário</label>
            <ng-select
              [items]="employees"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione uma função"
              formControlName="employeeId"
              [ngClass]="{
                'is-invalid':
                  appointmentForms.controls.employeeId.invalid &&
                  (appointmentForms.controls.employeeId.dirty ||
                    appointmentForms.controls.employeeId.touched)
              }"
            />
            <div
              *ngIf="
                appointmentForms.controls.employeeId.invalid &&
                (appointmentForms.controls.employeeId.dirty ||
                  appointmentForms.controls.employeeId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="appointmentForms.controls.employeeId.errors?.['required']"
              >
                Selecione um funcionário
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="typeahead-focus">Cliente</label>
                <ng-select
                  [items]="customers"
                  [searchable]="true"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Selecione uma função"
                  formControlName="customerId"
                  [ngClass]="{
                    'is-invalid':
                      appointmentForms.controls.customerId.invalid &&
                      (appointmentForms.controls.customerId.dirty ||
                        appointmentForms.controls.customerId.touched)
                  }"
                >
                </ng-select>
          </div>
          <div
            *ngIf="
              appointmentForms.controls.customerId.invalid &&
              (appointmentForms.controls.customerId.dirty ||
                appointmentForms.controls.customerId.touched)
            "
            class="invalid-feedback"
          >
            <div
              class="error"
              *ngIf="appointmentForms.controls.customerId.errors?.['required']"
            >
              Selecione um cliente
            </div>
          </div>
          <div class="form-group">
            <label for="typeahead-focus">Serviço</label>
            <ng-select
              [items]="services"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione uma função"
              formControlName="serviceId"
              [ngClass]="{
                'is-invalid':
                  appointmentForms.controls.serviceId.invalid &&
                  (appointmentForms.controls.serviceId.dirty ||
                    appointmentForms.controls.serviceId.touched)
              }"
            />
            <div
              *ngIf="
                appointmentForms.controls.serviceId.invalid &&
                (appointmentForms.controls.serviceId.dirty ||
                  appointmentForms.controls.serviceId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="appointmentForms.controls.serviceId.errors?.['required']"
              >
                Selecione um serviço
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="typeahead-focus">Produto</label>
            <ng-select
              [items]="products"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione uma função"
              formControlName="productId"
              [ngClass]="{
                'is-invalid':
                  appointmentForms.controls.productId.invalid &&
                  (appointmentForms.controls.productId.dirty ||
                    appointmentForms.controls.productId.touched)
              }"
            />
            <div
              *ngIf="
                appointmentForms.controls.productId.invalid &&
                (appointmentForms.controls.productId.dirty ||
                  appointmentForms.controls.productId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="appointmentForms.controls.productId.errors?.['required']"
              >
                Selecione um produto
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="startAt" class="form-label">Data Inicial</label>
            <input
              type="datetime-local"
              class="form-control form-control-lg"
              id="startAt"
              formControlName="startAt"
              [ngClass]="{
                'is-invalid':
                  appointmentForms.controls.startAt.invalid &&
                  (appointmentForms.controls.startAt.dirty ||
                    appointmentForms.controls.startAt.touched)
              }"
            />
            <div
              *ngIf="
                appointmentForms.controls.startAt.invalid &&
                (appointmentForms.controls.startAt.dirty ||
                  appointmentForms.controls.startAt.touched)
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="appointmentForms.controls.startAt.errors?.['required']"
                class="error"
              >
                Data Inicial é obrigatória
              </div>
              <div
                *ngIf="appointmentForms.controls.startAt.errors?.['invalidDate']"
                class="error"
              >
                Selecione uma data valida
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="concludesAt" class="form-label">Data Final</label>
            <input
              type="datetime-local"
              class="form-control form-control-lg"
              id="concludesAt"
              formControlName="concludesAt"
              [ngClass]="{
                'is-invalid':
                  appointmentForms.controls.concludesAt.invalid &&
                  (appointmentForms.controls.concludesAt.dirty ||
                    appointmentForms.controls.concludesAt.touched)
              }"
            />
            <div
              *ngIf="
                appointmentForms.controls.concludesAt.invalid &&
                (appointmentForms.controls.concludesAt.dirty ||
                  appointmentForms.controls.concludesAt.touched)
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="appointmentForms.controls.concludesAt.errors?.['required']"
                class="error"
              >
                Data Final é obrigatória
              </div>
              <div
                *ngIf="appointmentForms.controls.concludesAt.errors?.['invalidDate']"
                class="error"
              >
                Selecione uma data valida
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="info" class="form-label">Informações</label>
            <textarea
              class="form-control form-control-lg"
              id="info"
              placeholder="Informações"
              formControlName="info"
              rows="3"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="appointmentForms.reset()"
  >
    Limpar
  </button>
  <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
    Cadastrar
  </button>
</div>

import { Component, inject } from '@angular/core';
import { IProductResponse } from '../../models/interfaces/responses/IProductResponse';
import { IProductRequest } from '../../models/interfaces/requests/IProductRequest';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { EmployeesService } from '../../services/api/employees/employees.service';
import { ProductsService } from '../../services/api/products/products.service';
import { DeleteConfirmModalComponent } from '../../components/delete-confirm-modal/delete-confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProductComponent } from '../../components/updates/update-product/update-product.component';
import { ModalProductRegisterComponent } from '../../components/modal-registers/modal-product-register/modal-product-register.component';
import { UpdateTablesService } from '../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
})
export class ProductsComponent {
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  fields: { field: keyof IProductRequest; value: string }[] = [
    { field: 'name', value: 'Nome' },
    { field: 'description', value: 'Descrição' },
    { field: 'price', value: 'Preço' },
  ];
  data: IProductResponse[] = [];
  dataSliced: IProductResponse[] = [];
  productsService = inject(ProductsService);
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  showModal: boolean = false;
  originData: IProductResponse[] = [];
  productToDelete: string = '';

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private updateTablesService: UpdateTablesService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.updateTablesService.updateTable$.subscribe(() => {
      this.loadData();
    });
  }

  loadData() {
    this.productsService.getAllProducts().subscribe((products: any) => {
      this.data = products;
      this.originData = [...this.data];
      this.collectionSize = this.data.length;
      this.refreshTable(this.page);
    });
  }

  handleShowModal() {
    this.showModal = true;
    console.log(this.showModal);
  }

  openRegisterModal(): void {
    const modalRef = this.modalService.open(ModalProductRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Produto cadastrado com sucesso:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  closeModal() {
    this.showModal = false;
  }

  

  openEditModal(product: IProductResponse): void {
    const modalRef = this.modalService.open(UpdateProductComponent);
    modalRef.componentInstance.product = product;

    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Product updated:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  openDeleteModal(employeeId: string): void {
    this.productToDelete = employeeId;
    this.modalService.open(DeleteConfirmModalComponent).result.then(
      (result) => {
        if (result === 'yes') {
          this.deleteProduct(this.productToDelete);
        }
        this.productToDelete = '';
      },
      (reason) => {
        this.productToDelete = '';
      }
    );
  }

  deleteProduct(productId: string): void {
    this.productsService.deleteProduct(productId).subscribe((data) => {
      this.toastr.success(data, 'Deletado!');
      window.location.reload();
    });
  }

  refreshTable(currentPage: number, dataFiltered: IProductResponse[] = []) {
    const dataToPaginate = dataFiltered.length ? dataFiltered : this.data;
    const startIndex = (currentPage - 1) * this.pageSize;
    this.dataSliced = dataToPaginate.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  onSearchChange(search: Event) {
    const valueFilter = (search.target as HTMLInputElement).value.toLowerCase();
    let dataToFilter: IProductResponse[] = this.data;
    const dataFiltered = dataToFilter.filter((data: any) => {
      for (const val of Object.values(data)) {
        if (
          val &&
          val.toString().toLowerCase().includes(valueFilter)
        ) {
          return true;
        }
      }
      return false;
    });
    this.dataSliced = dataFiltered;
    console.log('data filtrada:', this.dataSliced);
    this.refreshTable(this.page, this.dataSliced);
  }

  
}

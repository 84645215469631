<div class="d-flex flex-column mt-5 gap-5 px-lg-4 px-md-1">
  <section class="table__container">
    <section class="header__box">
      <button
        type="button"
        (click)="openRegisterModal()"
        class="btn btn-lg btn-primary"
      >
        Adicionar funcionario
      </button>
      <div class="search__box">
        <input
          type="search"
          (input)="onSearchChange($event)"
          class="search"
          placeholder="Pesquise..."
        />
      </div>
    </section>
    <section class="table__box">
      <table>
        <thead class="sticky-top z-1">
          <tr>
            <th *ngFor="let field of fields">
              {{ field.value }}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of dataSliced">
            <td>{{ employee.name }}</td>
            <td>{{ employee.email }}</td>
            <td>{{ employee.phone | mask : "phone" }}</td>
            <td>{{ employee.cpf | mask : "cpf" }}</td>
            <td>{{ employee.startAt | minutesToTime }}</td>
            <td>{{ employee.finishAt | minutesToTime }}</td>
            <td>{{ employee.skill.name }}</td>
            <td>
              <div class="icons__edition__box">
                <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  (click)="openEditModal(employee)"
                >
                  <fa-icon [icon]="faPenToSquare" />
                  Editar funcionario
                </button>
                <button
                  type="submit"
                  class="btn btn-danger btn-lg"
                  (click)="openDeleteModal(employee.id)"
                >
                  <fa-icon style="cursor: pointer" [icon]="faTrash" />
                  Deletar funcionario
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <ng-template #deleteModal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              Confirmar Exclusão
            </h4>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="modal.dismiss('cancel')"
            ></button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja deletar esse funcionario?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-lg"
              (click)="modal.dismiss('cancel')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger btn-lg"
              (click)="modal.close('yes')"
            >
              Yes
            </button>
          </div>
        </ng-template>
      </table>
    </section>
    <div class="pagination__box">
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshTable(page)"
        size="lg"
      >
      </ngb-pagination>

      <select
        class="form-select"
        style="width: auto; z-index: 2; height: 35.75px; font-size: 1.4rem"
        [(ngModel)]="pageSize"
        (ngModelChange)="refreshTable(page)"
      >
        <option [ngValue]="10">10 items</option>
        <option [ngValue]="20">20 items per page</option>
        <option [ngValue]="50">50 items per page</option>
      </select>
    </div>
  </section>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISkillRequest } from '../../../models/interfaces/requests/ISkillRequest';
import { ISkillResponse } from '../../../models/interfaces/responses/ISkillResponse';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

export interface ISkillUpdate {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor(private http: HttpClient, private cookie: CookieService) {}

  getAllSkills() {
    return this.http
      .get<any>(`${this.API_URL}/skills`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const skill = response.content;
          return skill;
        })
      );
  }

  getSkillById(id: string): Observable<ISkillResponse> {
    return this.http
      .get<any>(`${this.API_URL}/skills/${id}`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const skill = response.content;
          return skill;
        })
      );
  }

  getSkillByName(name: string): Observable<ISkillResponse> {
    return this.http
      .get<any>(`${this.API_URL}/skills/byName/${name}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          const skill = response.content;
          console.log('skill',skill);
          return {
            id: skill.id,
            name: skill.name,
          };
        })
      );
  }

  postSkill(skill: ISkillRequest) {
    return this.http
      .post<any>(`${this.API_URL}/skills`, skill)
      .pipe(catchError(() => throwError('funçao ja existente')));
  }

  putSkill(skill: ISkillUpdate) {
    return this.http
      .put<any>(`${this.API_URL}/skills`, skill, { headers: this.headers })
      .pipe(
        map((response) => {
          const skill = response.content;
          return skill;
        })
      );
  }

  deleteSkill(skillId: string): Observable<any> {
    return this.http.delete<any>(`${this.API_URL}/skills/${skillId}`, { headers: this.headers });
  } 
}

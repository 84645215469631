import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from '../../auth/auth-guard/auth-guard.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService {
  constructor(private router: Router, private authGuard: AuthGuardService,private cookie:CookieService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const expectedRoles = route.data['expectedRoles'];
    const userRole = this.getUserRole();
    if (!this.authGuard.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }
    if (!expectedRoles.includes(userRole)) {
      this.router.navigate(['/login']);
      return false;
    }
    this.authGuard.isLoggedIn();
    return true;
  }

  private getUserRole(): string {
    const skillCookie = this.cookie.get('skill');
    console.log(skillCookie)
    if (!skillCookie) {
      return 'customer';
    } else if (skillCookie === 'administrator') {
      return 'administrator';
    } else {
      return 'employee';
    }
  }
}

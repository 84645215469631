<div class="row mt-5 gap-5 px-lg-4 px-md-1">
    <div class="col">
      <section class="table__container">
        <section class="header__box">
          <button
            type="button"
            (click)="openRegisterModal()"
            class="btn btn-lg btn-primary"
          >
            Adicionar produto
          </button>
          <div class="search__box">
            <input
              type="search"
              (input)="onSearchChange($event)"
              class="search"
              placeholder="Pesquise..."
            />
          </div>
        </section>
        <section class="row mt-4">
          <div class="col">
            <section class="table__box">
              <table>
                <thead class="sticky-top z-1">
                  <tr>
                    <th *ngFor="let field of fields">
                      {{ field.value }}
                    </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of dataSliced">
                    <td>{{ product.name }}</td>
                    <td>{{ product.description }}</td>
                    <td>{{ product.price }}</td>
                    <td>
                      <div class="icons__edition__box">
                        <button type="submit" class="btn btn-success btn-lg" (click)="openEditModal(product)">
                          <fa-icon [icon]="faPenToSquare" />
                          Editar produto
                        </button>
                        <button type="submit" class="btn btn-danger btn-lg" (click)="openDeleteModal(product.id)">
                          <fa-icon
                            style="cursor: pointer"
                            [icon]="faTrash"

                          />
                          Deletar produto
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </section>
        <div class="pagination__box">
          <ngb-pagination
            [collectionSize]="collectionSize"
            [(page)]="page"
            [pageSize]="pageSize"
            (pageChange)="refreshTable(page)"
            size="lg"
          >
          </ngb-pagination>

          <select
            class="form-select"
            style="width: auto; z-index: 2;height:35.75px;font-size:1.4rem"
            [(ngModel)]="pageSize"
            (ngModelChange)="refreshTable(page)"
          >
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="20">20 items per page</option>
            <option [ngValue]="50">50 items per page</option>
          </select>
        </div>
      </section>
    </div>
  </div>

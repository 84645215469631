<div class="modal-header">
    <h4 class="modal-title">Editar Funcionario</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group mb-3">
        <label for="employeeName">Nome</label>
        <input
          type="text"
          id="employeeName"
          class="form-control form-control-lg"
          [(ngModel)]="service.name"
          name="name"
        />
      </div>
      <div class="form-group mb-3">
        <label for="productDescription">Descrição</label>
        <input
          type="text"
          id="productDescription"
          class="form-control form-control-lg"
          [(ngModel)]="service.description"
          name="description"
        />
      </div>
      <div class="form-group mb-3">
        <label for="productPrice">Preço</label>
        <input
          type="text"
          id="productPrice"
          class="form-control form-control-lg"
          [(ngModel)]="service.price"
          name="price"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary btn-lg"
      (click)="activeModal.dismiss('cancel')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary btn-lg"
      (click)="update(service)"
    >
      Save changes
    </button>
  </div>
  
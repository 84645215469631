import { Component } from '@angular/core';

@Component({
  selector: 'app-cancellations',
  templateUrl: './cancellations.component.html',
  styleUrl: './cancellations.component.scss'
})
export class CancellationsComponent {

}

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { IProductResponse } from '../../../models/interfaces/responses/IProductResponse';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from '../../../services/api/customers/customers.service';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';
import { ServicesService } from '../../../services/api/services/services.service';
import { ProductsService } from '../../../services/api/products/products.service';
import { SalesService } from '../../../services/api/sales/sales.service';
import { ISaleRequest } from '../../../models/interfaces/requests/ISaleRequest';
import { CustomEmailValidator } from '../../../validators/email.validator';
import { Router } from '@angular/router';
import { AppointmentsService } from '../../../services/api/appointments/appointments.service';
import { CookieService } from 'ngx-cookie-service';
import { SkillsService } from '../../../services/api/skills/skills.service';

export interface PaymentMethod {
  id: number;
  name: string;
}

@Component({
  selector: 'app-modal-sale-register',
  templateUrl: './modal-sale-register.component.html',
  styleUrl: './modal-sale-register.component.scss',
})
export class ModalSaleRegisterComponent {
  isVerify: boolean = false;
  fb = inject(NonNullableFormBuilder);
  cookie = inject(CookieService);
  router = inject(Router);
  methods: PaymentMethod[] = [
    { id: 0, name: 'Cartão de Crédito' },
    { id: 1, name: 'Cartão de Débito' },
    { id: 2, name: 'Pix' },
    { id: 3, name: 'Dinheiro' },
  ];
  currentComponent: string = '';
  customerName = '';
  employeeName = '';
  productName = '';
  employeeId: string = '';
  customerId: string = '';
  customer: ICustomerResponse;
  products: IProductResponse[] = [];
  serviceId: string = '';
  employeesList: string[] = [];
  customersList: string[] = [];
  servicesList: string[] = [];
  employees: IEmployeeResponse[] = [];
  customers: ICustomerResponse[] = [];
  services: IProductResponse[] = [];
  verifyCustomer = this.fb.group({
    email: this.fb.control('', {
      validators: [Validators.required, CustomEmailValidator],
    }),
  });
  productsId: string[] = [];
  servicesId: string[] = [];
  dataInvite: any = {};
  salesForm = this.fb.group({
    employeeId: ['', Validators.required],
    customerId: ['', Validators.required],
    appointmentId: '',
    date: [new Date()],
    method: [-1, Validators.required],
    proofOfPayment: [''],
    skillsId: [this.cookie.get('skill'), [Validators.required]],
    status: [0, [Validators.required]],
    storeId: [this.cookie.get('storeId')],
  });
  data: any[] = [];
  selectedId: string | null = null;
  isScheduled: boolean = false;
  isLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private customerService: CustomersService,
    private employeesService: EmployeesService,
    private productsService: ProductsService,
    private servicesService: ServicesService,
    private appointmentService: AppointmentsService,
    private salesService: SalesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService,
    private skillsService: SkillsService
  ) {}

  ngOnInit(): void {
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.employees = employees;
    });
    this.customerService.getAllCustomers().subscribe((customers) => {
      this.customers = customers;
    });
    this.productsService.getAllProducts().subscribe((products) => {
      this.products = products;
    });
    this.servicesService.getAllServices().subscribe((services) => {
      this.services = services;
    });
    this.skillsService
      .getSkillByName(this.cookie.get('skill'))
      .subscribe((skills: any) => {
        this.salesForm.get('skillsId')?.setValue(skills.id);
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.salesForm.valid) {
      this.onSubmit();
    }
  }

  get canCheckAppointments(): boolean {
    return this.customerName.trim() !== '' && this.employeeName.trim() !== '';
  }

  showScheduler() {
    this.isScheduled = !this.isScheduled;
    const body = {
      employeeId: this.salesForm.get('employeeId')?.value,
      customerId: this.salesForm.get('customerId')?.value,
    };
    console.log('body', body);
    this.appointmentService
      .postAppointmentsByEmployeeAndCustomer(body)
      .subscribe((appointment: any) => {
        this.data = appointment;
      });
  }

  convertTimeToMinutes(time: string) {
    let [hours, minutes] = time.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  }

  convertMinutesToTime(minutes: number) {
    let hours: string | number = Math.floor(minutes / 60);
    let remainingMinutes: string | number = minutes % 60;
    remainingMinutes =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;
    hours = hours < 10 ? '0' + hours : hours;
    return `${hours}:${remainingMinutes}`;
  }

  get f() {
    return this.salesForm.controls;
  }

  selectLine(item: any): void {
    this.selectedId = this.selectedId === item.id ? null : item.id;
    if (this.selectedId) {
      this.salesForm.get('appointmentId')?.setValue(this.selectedId);
      this.productsId = [item.product.id];
      this.servicesId = [item.service.id];
    } else {
      this.salesForm.get('appointmentId')?.setValue('');
      this.productsId = [''];
      this.servicesId = [''];
    }
  }

  onProductsChange(event: any) {
    console.log('event', event);
    this.productsId = event.map((product: any) => product.id);
  }

  onServiceChange(event: any) {
    console.log('event', event);
    this.servicesId = event.map((service: any) => service.id);
  }

  onSubmit() {
    const originalDate = this.salesForm.get('date')?.value;
    const convertedDate = new Date(originalDate + 'T00:00:00.000Z').toISOString();
    console.log(this.productsId,this.servicesId)
    const newsale: ISaleRequest = {
      ...this.salesForm.getRawValue(),
      date: convertedDate,
      appointmentId:
        this.salesForm.get('appointmentId')?.value == ''
          ? null
          : this.salesForm.get('appointmentId')?.value,
      productsId: this.productsId,
      servicesId: this.servicesId,
    };
    console.log('newsale', newsale);
    this.salesService.postSale(newsale).subscribe((response) => {
      this.toastr.success('Venda cadastrada com sucesso');
      this.cdr.detectChanges(); // Força a detecção de mudanças
      this.updateTablesService.updateTable();
      this.activeModal.dismiss('success');
    });
  }
}

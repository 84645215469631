import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs';
import { ICancellationRequest } from '../../../models/interfaces/requests/ICancellationRequest';

@Injectable({
  providedIn: 'root',
})
export class CancellationsService {
  private API_URL = environment.API_URL;
  private cookie = inject(CookieService);
  private http = inject(HttpClient);
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor() {}

  getAllCancellations() {
    return this.http
      .get<any>(`${this.API_URL}/cancellations`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const cancellations = response.content;
          return cancellations;
        })
      );
  }

  postCancellation(cancellation: ICancellationRequest) {
    return this.http
      .post(`${this.API_URL}/cancellations`, cancellation, {
        headers: this.headers,
      })
      .pipe(
        map((response: any) => {
          const cancellations = response.content;
          return cancellations;
        })
      );
  }
}

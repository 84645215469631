<div class="modal-header">
  <h2 class="modal-title">Cadastrar serviço</h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div
    class="modal-body-content"
    style="max-height: 70vh; overflow-y: auto; padding: 10px"
  >
    <div class="content-section">
      <div class="accordion-body">
        <form
          class="d-flex flex-column gap-3"
          [formGroup]="serviceForms"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="serviceName">Nome</label>
            <input
              type="text"
              id="serviceName"
              class="form-control form-control-lg"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  serviceForms.controls.name.invalid &&
                  (serviceForms.controls.name.dirty ||
                    serviceForms.controls.name.touched)
              }"
            />
            <div
              *ngIf="
                serviceForms.controls.name.invalid &&
                (serviceForms.controls.name.dirty ||
                  serviceForms.controls.name.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="serviceForms.controls.name.errors?.['required']"
              >
                Nome é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="serviceDescription">Descrição</label>
            <input
              type="text"
              id="serviceDescription"
              class="form-control form-control-lg"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  serviceForms.controls.description.invalid &&
                  (serviceForms.controls.description.dirty ||
                    serviceForms.controls.description.touched)
              }"
            />
            <div
              *ngIf="
                serviceForms.controls.description.invalid &&
                (serviceForms.controls.description.dirty ||
                  serviceForms.controls.description.touched)
              "
              class="invalid-feedback"
            >
              @if(serviceForms.controls.description.errors?.['required']){
              <div class="error">Descrição é obrigatório</div>
              }
            </div>
          </div>
          <div class="form-group">
            <label for="servicePrice">Preço</label>
            <input
              type="text"
              id="servicePrice"
              class="form-control form-control-lg"
              mask="separator.2"
              prefix="R$ "
              thousandSeparator="."
              decimalMarker=","
              (ngModelChange)="formatPrice($event)"
              formControlName="price"
              [ngClass]="{
                'is-invalid':
                  serviceForms.controls.price.invalid &&
                  (serviceForms.controls.price.dirty ||
                    serviceForms.controls.price.touched)
              }"
              name="price"
            />
            <div
              *ngIf="
                serviceForms.controls.price.invalid &&
                (serviceForms.controls.price.dirty ||
                  serviceForms.controls.price.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="serviceForms.controls.price.errors?.['required']"
              >
                Preço é obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="serviceForms.reset()"
  >
    Limpar
  </button>
  <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
    Cadastrar
  </button>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeesComponent } from './views/employees/employees.component';
import { CustomersComponent } from './views/customers/customers.component';
import { RegisterCustomerComponent } from './components/registers/register-customer/register-customer.component';
import { LoginComponent } from './views/login/login.component';
import { RoleGuardService } from './services/guards/role-guard/role-guard.service';
import { HomeComponent } from './views/home/home.component';
import { ProductsComponent } from './views/products/products.component';
import { RegisterProductComponent } from './components/registers/register-product/register-product.component';
import { RegisterAppointmentComponent } from './components/registers/register-appointment/register-appointment.component';
import { AppointmentsComponent } from './views/appointments/appointments.component';
import { ProfileComponent } from './views/profile/profile.component';
import { SystemSettingsComponent } from './views/system-settings/system-settings.component';
import { SalesComponent } from './views/sales/sales.component';
import { RegisterSaleComponent } from './components/registers/register-sale/register-sale.component';
import { RegisterAddressComponent } from './components/registers/register-address/register-address.component';
import { CustomersAppointmentsComponent } from './views/customers-appointments/customers-appointments.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SkillComponent } from './views/skill/skill.component';
import { RegisterCancellationsComponent } from './components/registers/register-cancellations/register-cancellations.component';
import { CancellationsComponent } from './views/cancellations/cancellations.component';
import { ServicesService } from './services/api/services/services.service';
import { ServicesComponent } from './views/services/services.component';

const routes: Routes = [
  {
    path: 'employee',
    component: EmployeesComponent,
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
  },
  {
    path: 'product',
    component: ProductsComponent,
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
  },
  {
    path: 'service',
    component: ServicesComponent,
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
  },
  { path: 'scheduler', component: AppointmentsComponent },
  {
    path: 'customer',
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
    component: CustomersComponent,
  },
  {
    path: 'sale',
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
    component: SalesComponent,
  },
  {
    path: 'skill',
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
    component: SkillComponent,
  },
  {
    path: 'system-settings',
    component: SystemSettingsComponent,
    canActivate: [RoleGuardService],
    data: { expectedRoles: ['administrator'] },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { expectedRoles: ['administrator'] },
    canActivate: [RoleGuardService],
  },
  { path: 'cancellation', component: CancellationsComponent },
  { path: 'customer-appointments', component: CustomersAppointmentsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: '', redirectTo: 'scheduler', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

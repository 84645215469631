import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { AppointmentsService } from '../../../services/api/appointments/appointments.service';
import { CookieService } from 'ngx-cookie-service';
import { DateAppointmentValidator } from '../../../validators/date-appointment.validator';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { ToastrService } from 'ngx-toastr';
import { CustomersService } from '../../../services/api/customers/customers.service';
import { ProductsService } from '../../../services/api/products/products.service';
import { ServicesService } from '../../../services/api/services/services.service';
import { IProductResponse } from '../../../models/interfaces/responses/IProductResponse';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { ModalCustomerRegisterComponent } from '../modal-customer-register/modal-customer-register.component';
import { ModalServiceRegisterComponent } from '../modal-service-register/modal-service-register.component';
import { ModalProductRegisterComponent } from '../modal-product-register/modal-product-register.component';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-appointment-register',
  templateUrl: './modal-appointment-register.component.html',
  styleUrl: './modal-appointment-register.component.scss',
})
export class ModalAppointmentRegisterComponent {
  fb = inject(NonNullableFormBuilder);
  cookie = inject(CookieService);
  appointmentForms = this.fb.group({
    storeId: this.fb.control(this.cookie.get('storeId')),
    employeeId: this.fb.control('', {
      validators: [Validators.required],
    }),
    customerId: this.fb.control('', {
      validators: [Validators.required],
    }),
    serviceId: this.fb.control('', {
      validators: [Validators.required],
    }),
    productId: this.fb.control('', {
      validators: [Validators.required],
    }),
    startAt: this.fb.control('', {
      validators: [Validators.required, DateAppointmentValidator],
    }),
    concludesAt: this.fb.control('', {
      validators: [Validators.required, DateAppointmentValidator],
    }),
    info: this.fb.control(''),
  });
  employees: IEmployeeResponse[] = [];
  products: IProductResponse[] = [];
  services: IProductResponse[] = [];
  customers: ICustomerResponse[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private appointmentService: AppointmentsService,
    private customersService: CustomersService,
    private employeesService: EmployeesService,
    private productsService: ProductsService,
    private servicesServices: ServicesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.employees = employees;
    });
    this.productsService.getAllProducts().subscribe((products) => {
      this.products = products;
    });
    this.servicesServices.getAllServices().subscribe((services) => {
      this.services = services;
    });
    this.customersService.getAllCustomers().subscribe((customers) => {
      this.customers = customers;
    });
  }

  onSubmit() {
    this.appointmentService
      .postAppointment(this.appointmentForms.getRawValue())
      .subscribe((response) => {
        this.toastr.success('Agendamento cadastrado com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success');
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.appointmentForms.valid) {
      this.onSubmit();
    }
  }

  openCustomerRegisterModal(){
    const modalRef = this.modalService.open(ModalCustomerRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });

    modalRef.result
    .then((result) => {
      if (result) {
        console.log('Cliente cadastrado com sucesso:', result);
        this.customersService.getAllCustomers().subscribe((customers) => {
          this.customers = customers;
          this.appointmentForms.controls.customerId.setValue(result.id);
          this.cdr.detectChanges();
        });
      }
    })
    .catch((error) => {
      console.log('Modal dismissed with:', error);
    });
  }

  openProductRegisterModal(){
    const modalRef = this.modalService.open(ModalProductRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });

    modalRef.result
    .then((result) => {
      if (result) {
        console.log('Produto cadastrado com sucesso:', result);
      }
    })
    .catch((error) => {
      console.log('Modal dismissed with:', error);
    });
  }

  openServiceRegisterModal(){
    const modalRef = this.modalService.open(ModalServiceRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });

    modalRef.result
    .then((result) => {
      if (result) {
        console.log('Serviço cadastrado com sucesso:', result);
      }
    })
    .catch((error) => {
      console.log('Modal dismissed with:', error);
    });
  }
}

<div class="modal-header">
  <h2 class="modal-title">Cadastrar função</h2>
  <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div
      class="modal-body-content"
      style="max-height: 70vh; overflow-y: auto; padding: 10px"
    >
      <div class="content-section">
        <div class="accordion-body">
          <form
            class="d-flex flex-column gap-3"
            [formGroup]="skillForms"
            (ngSubmit)="onSubmit()"
          >
            <div class="form-group">
              <label for="skillName">Nome</label>
              <input
                type="text"
                id="skillName"
                class="form-control form-control-lg"
                formControlName="name"
                [ngClass]="{
                  'is-invalid':
                    skillForms.controls.name.invalid &&
                    (skillForms.controls.name.dirty ||
                      skillForms.controls.name.touched)
                }"
              />
              <div
                *ngIf="
                  skillForms.controls.name.invalid &&
                  (skillForms.controls.name.dirty ||
                    skillForms.controls.name.touched)
                "
                class="invalid-feedback"
              >
                <div
                  class="error"
                  *ngIf="skillForms.controls.name.errors?.['required']"
                >
                  Nome da função é obrigatório
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger btn-lg"
      (click)="activeModal.dismiss('cancel')"
    >
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-secondary btn-lg"
      (click)="skillForms.reset()"
    >
      Limpar
    </button>
    <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
      Cadastrar
    </button>
  </div>

<div class="container mt-5">
  <div class="row">
    <div class="col-12">
      <div class="settings-card">
        <div class="row">
          <div class="col-12 max-height-col">
            <app-configurations />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

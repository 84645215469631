import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  private masks:any = {
    phone: (value: string) => this.applyMask(value, '(00) 0 0000-0000'),
    cpf: (value: string) => this.applyMask(value, '000.000.000-00'),
    cnpj: (value: string) => this.applyMask(value, '00.000.000/0000-00'),
    cep: (value: string) => this.applyMask(value, '00000-000'),
    date: (value: string) => this.applyMask(value, '00/00/0000'),
  };

  transform(value: string, maskType: string): string {
    const maskFunction = this.masks[maskType];
    if (!maskFunction) {
      return value;
    }
    return maskFunction(value);
  }

  private applyMask(value: string, mask: string): string {
    let maskedValue = '';
    let valueIndex = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        if (value[valueIndex] !== undefined) {
          maskedValue += value[valueIndex];
          valueIndex++;
        } else {
          break;
        }
      } else {
        maskedValue += mask[i];
      }
    }
    return maskedValue;
  }

}

<div class="row mt-5 gap-5 px-lg-4 px-md-1">
  <div class="col">
    <section class="table__container">
      <section class="header__box">
        <button
          type="button"
          (click)="openRegisterModal()"
          class="btn btn-lg btn-primary"
        >
          Adicionar venda
        </button>
        <div class="search__box">
          <input
            type="search"
            (input)="onSearchChange($event)"
            class="search"
            placeholder="Pesquise..."
          />
        </div>
      </section>
      <section class="row mt-4">
        <div class="col">
          <section class="table__box">
            <table>
              <thead class="sticky-top z-1">
                <tr>
                  <th>Cliente</th>
                  <th>Funcionário</th>
                  <th>Produtos</th>
                  <th>Serviços</th>
                  <th>Preço da venda</th>
                  <th>Forma de pagamento</th>
                  <th>Inicio do agendamento</th>
                  <th>Termino do agendamento</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of dataSliced">
                  <td>{{ sale.customer.name }}</td>
                  <td>{{ sale.employee.name }}</td>
                  <td>
                    @if (sale.products && sale.products.length > 0) {
                      <ng-select [items]="sale.products" bindLabel="name" [clearable]="false" [searchable]="false" [(ngModel)]="sale.products[0]">
                        <ng-template ng-option-tmp let-item="item">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    } @else {
                      <span>Nenhum produto utilizado</span>
                    }
                  </td>
                  <td>
                    @if (sale.services && sale.services.length > 0) {
                      <ng-select [items]="sale.services" bindLabel="name" [clearable]="false" [searchable]="false" [(ngModel)]="sale.services[0]">
                        <ng-template ng-option-tmp let-item="item">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                    } @else {
                      <span>Nenhum serviço utilizado</span>
                    }
                  </td>
                  <td>{{ sale.fullPrice }}</td>
                  @switch(sale.method){ @case(0){
                  <td>Cartão de crédito</td>
                  } @case(1){
                  <td>Cartão de débito</td>
                  } @case(2){
                  <td>Pix</td>
                  } @case(3){
                  <td>Dinheiro</td>
                  } @default{
                  <td>Outro</td>
                  } } @if(sale.appointment!==null){
                  <td>
                    {{ sale.appointment.startAt | isoToBrDate }}
                  </td>
                  <td>
                    {{ sale.appointment.concludesAt | isoToBrDate }}
                  </td>
                  @if(sale.status===4){
                    <td class="text-danger">{{ "Cancelada" | uppercase }}</td>
                  }
                  @else{
                    <td style="color:green">{{ "Concluida" | uppercase }}</td>
                  }
                  } @else {
                  <td>Não possui agendamento</td>
                  <td>Não possui agendamento</td>
                  @if(sale.status===4){
                    <td class="text-danger">{{ "Cancelada" | uppercase }}</td>
                  }
                  @else{
                    <td style="color:green">{{ "Concluida" | uppercase }}</td>
                  }
                  }
                  <td>
                    <div class="d-flex flex-column gap-2">
                      <div class="icons__edition__box">
                        <button
                          type="submit"
                          class="btn btn-success btn-lg d-none"
                        >
                          <fa-icon [icon]="faPenToSquare" />
                          Editar venda
                        </button>
                        <button
                          type="submit"
                          class="btn btn-danger btn-lg"
                          (click)="cancelSale(sale.id)"
                        >
                          <fa-icon style="cursor: pointer" [icon]="faTrash" />
                          Cancelar venda
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </section>
      <div class="pagination__box">
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [pageSize]="pageSize"
          (pageChange)="refreshTable(page)"
          size="lg"
        >
        </ngb-pagination>

        <select
          class="form-select"
          style="width: auto; z-index: 2"
          [(ngModel)]="pageSize"
          (ngModelChange)="refreshTable(page)"
        >
          <option [ngValue]="10">10 items per page</option>
          <option [ngValue]="20">20 items per page</option>
          <option [ngValue]="50">50 items per page</option>
        </select>
      </div>
    </section>
  </div>
</div>

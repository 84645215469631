import { Injectable } from '@angular/core';
import {
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  switchMap,
  toArray,
} from 'rxjs';
import { EmployeesService } from '../employees/employees.service';
import { StoresService } from '../stores/stores.service';
import { CustomersService } from '../customers/customers.service';
import { ProductsService } from '../products/products.service';
import { IAppointmentRequest } from '../../../models/interfaces/requests/IAppointmentRequest';
import { environment } from '../../../../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { IAppointment } from '../../../models/interfaces/registers/IAppointment';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { ServicesService } from '../services/services.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor(
    private employeesService: EmployeesService,
    private storesService: StoresService,
    private customersService: CustomersService,
    private productsService: ProductsService,
    private servicesService: ServicesService,
    private cookie: CookieService,
    private http: HttpClient
  ) {}

  getAllAppointments() {
    return this.http
      .get(`${this.API_URL}/appointments`, {
        headers: this.headers,
      })
      .pipe(
        map((response: any) => {
          const appointmentsArrays = Object.values(response.content);
          return appointmentsArrays;
        }),
        mergeMap((arrays) => {
          return from(arrays);
        }),
        map((data: any) => ({
          title: data.service.name,
          start: new Date(data.startAt),
          end: new Date(data.concludesAt),
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          extendedProps: {
            service: data.service.name,
            price: data.service.price,
            professional: data.employee.name,
            product: data.product.name,
            appointment: data,
            client: data.customer.name,
            details: data.info,
          },
        })),
        toArray()
      );
  }

  postAppointmentByEmployee(employeeId: string, month: number) {
    const data = { employeeId: employeeId, month: month };
    return this.http.post(`${this.API_URL}/appointments/byEmployee`, data).pipe(
      map((response: any) => {
        const appointmentsArrays = Object.values(response.content);
        return appointmentsArrays;
      }),
      mergeMap((arrays) => from(arrays)),
      mergeMap((appointments: any) => {
        return from(appointments);
      }),
      map((data: any) => ({
        title: data.service.name,
        start: new Date(data.startAt),
        end: new Date(data.concludesAt),
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        extendedProps: {
          service: data.service.name,
          price: data.service.price,
          professional: data.employee.name,
          product: data.product.name,
          appointment: data,
          client: data.customer.name,
          details: data.info,
        },
      })),
      toArray()
    );
  }

  postAppointmentsByEmployeeAndCustomer(appointment: any) {
    return forkJoin({
      employee: this.employeesService.getEmployeeById(appointment.employeeId),
      customer: this.customersService.getCustomerById(appointment.customerId),
    }).pipe(
      switchMap((results: any) => {
        const requestPayload = {
          employeeId: results.employee.id,
          customerId: results.customer.id,
        };
        return this.http.post<IAppointment>(
          `${this.API_URL}/appointments/byEmployeeAndCustomer`,
          requestPayload
        );
      })
    );
  }

  postAppointmentByEmployeeId(id: string, month: number): Observable<any> {
    const body = { id: id, month: month };
    return this.http
      .post<any>(`${this.API_URL}/appointments/byEmployee`, body, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          const appointment = response.content;
          return appointment;
        })
      );
  }

  postAppointmentByCustomerEmail(email: string) {
    const body = { email: email };
    return this.http
      .post<any>(`${this.API_URL}/appointments/${email}`, body, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          const appointment = response.content;
          return appointment;
        })
      );
  }

  postAppointment(appointment: IAppointmentRequest) {
    return forkJoin({
      employee: this.employeesService.getEmployeeById(appointment.employeeId),
      customer: this.customersService.getCustomerById(appointment.customerId),
      service: this.servicesService.getServiceById(appointment.serviceId),
      product:
        appointment.productId === ''
          ? ''
          : this.productsService.getProductById(appointment.productId),
    }).pipe(
      switchMap((results: any) => {
        const requestPayload: any = {
          ...appointment,
          startAt: new Date(appointment.startAt),
          concludesAt: new Date(appointment.concludesAt),
          serviceId: results.service.id,
          status: 0,
          productId: results.product === '' ? '' : results.product.id,
          customerId: results.customer.id,
          employeeId: results.employee.id,
        };
        return this.http.post<any>(
          `${this.API_URL}/appointments`,
          requestPayload
        );
      })
    );
  }

  deleteAppointmentById(id: string) {
    return this.http
      .delete(`${this.API_URL}/appointments/${id}`, {
        headers: this.headers,
      })
      .pipe(
        map((results: any) => {
          const deleteMessage = results.message;
          return deleteMessage;
        })
      );
  }
}

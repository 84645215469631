import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environments';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { IProductResponse } from '../../../models/interfaces/responses/IProductResponse';
import { IProduct } from '../../../models/interfaces/registers/IProduct';
import { CookieService } from 'ngx-cookie-service';

export interface IProductUpdate {
  id: string;
  name: string;
  description: string;
  price: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor(private http: HttpClient, private cookie: CookieService) {}

  getAllProducts(): Observable<IProductResponse[]> {
    return this.http
      .get<any>(`${this.API_URL}/products`, { headers: this.headers })
      .pipe(
        map((response: any) =>
          response.content.map((products: IProductResponse) => products)
        )
      );
  }

  getProductById(id: string): Observable<IProductResponse> {
    return this.http
      .get<any>(`${this.API_URL}/products/${id}`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const product = response.content;
          return product;
        })
      );
  }

  postProduct(product: IProduct) {
    let newProduct: IProduct = {
      ...product,
      storeId:this.cookie.get('storeId')
    };
    return this.http.post<IProductResponse>(
      `${this.API_URL}/products`,
      newProduct
    );
  }

  putProduct(product: IProductUpdate) {
    return this.http
      .put(`${this.API_URL}/products`, product, { headers: this.headers })
      .pipe(
        map((product: any) => {
          const response = product.content;
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      errorMessage = `Server-side error: ${error.status}\nMessage: ${error.message}`;
    }
    // Exibe o erro no console
    console.error(errorMessage);
    // Retorna um observable com uma mensagem de erro amigável
    return throwError(() => new Error(errorMessage));
  }

  deleteProduct(id: string) {
    return this.http
      .delete(`${this.API_URL}/products/${id}`, { headers: this.headers })
      .pipe(
        map((product: any) => {
          const response = product.message;
          return response;
        })
      );
  }
}

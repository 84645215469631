<div class="modal-header">
  <h4 class="modal-title">Editar funcionario</h4>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="employeeName">Nome</label>
      <input
        type="text"
        id="employeeName"
        class="form-control form-control-lg"
        [(ngModel)]="employee.name"
        name="name"
      />
    </div>
    <div class="form-group">
      <label for="employeeEmail">Email</label>
      <input
        type="email"
        id="employeeEmail"
        class="form-control form-control-lg"
        [(ngModel)]="employee.email"
        name="email"
      />
    </div>
    <div class="form-group">
      <label for="employeePhone">Telefone</label>
      <input
        type="text"
        id="employeePhone"
        class="form-control form-control-lg"
        mask="(00)0 0000-0000"
        [(ngModel)]="employee.phone"
        name="phone"
      />
    </div>
    <div class="form-group">
      <label for="employeeCpf">CPF</label>
      <input
        type="text"
        id="employeeCpf"
        mask="000.000.000-00"
        class="form-control form-control-lg"
        [(ngModel)]="employee.cpf"
        name="cpf"
      />
    </div>
    <div class="form-group">
      <label for="employeeStartAt">Começa em</label>
      <input
        [type]="inputType"
        id="employeeStartAt"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [value]="employee.startAt | minutesToTime"
        class="form-control form-control-lg"
        (change)="timeConverterStartAt($event)"
        name="startAt"
      />
    </div>
    <div class="form-group">
      <label for="employeeFinishAt">Termina em</label>
      <input
        [type]="inputType"
        id="employeeFinishAt"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [value]="employee.finishAt | minutesToTime"
        class="form-control form-control-lg"
        (change)="timeConverterFinishAt($event)"
        name="finishAt"
      />
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
      <label for="typeahead-focus">Função</label>
      <ng-select
        [items]="skills"
        [searchable]="true"
        bindLabel="name"
        bindValue="id"
        placeholder="Selecione uma função"
        [(ngModel)]="employee.skill.id"
        name="skillId"
      />
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-lg"
    (click)="update(employee)"
  >
    Save changes
  </button>
</div>

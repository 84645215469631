import { Component, inject } from '@angular/core';
import { StoresService } from '../../../services/api/stores/stores.service';
import { CookieService } from 'ngx-cookie-service';
import { IStoreResponse } from '../../../models/interfaces/responses/IStoreResponse';
import { IAdress } from '../../../models/interfaces/registrations/IAdress';
import { NonNullableFormBuilder } from '@angular/forms';
import { IStore } from '../../../models/interfaces/registrations/IStore';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrl: './configurations.component.scss',
})
export class ConfigurationsComponent {
  private storeService = inject(StoresService);
  private cookie = inject(CookieService);
  private toastr=inject(ToastrService)
  fantasyName = '';
  socialReason = '';
  city = '';
  ufSubscribe = '';
  street = '';
  uf = '';
  cnpj = '';
  citySubscribe = '';
  store: IStoreResponse;
  address: string;
  setTheme(theme: string) {
    document.body.setAttribute('data-theme', theme);
  }

  ngOnInit(): void {
    this.storeService
      .getStoreId(this.cookie.get('storeId'))
      .subscribe((response: any) => {
        this.store = response;
        this.socialReason = response.socialReason;
        this.fantasyName = response.fantasyName;
        this.city = response.address.city;
        this.ufSubscribe = response.ufSubscribe;
        this.street = response.address.street + ',' + response.address.number;
        this.uf = response.address.state;
        this.cnpj = response.cnpj;
        this.citySubscribe = response.citySubscribe;
      });
  }

  updateConfigurations() {
    const street = {
      ...this.store,
      fantasyName: this.fantasyName,
      ufSubscribe: this.ufSubscribe,
      cnpj: this.cnpj,
      citySubscribe: this.citySubscribe,
    };
    this.storeService.putStore(street).subscribe((response: any) => {
      this.toastr.success('Loja alterada com sucesso!','Sucesso!')
    });
  }
}

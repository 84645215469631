import { Component, inject } from '@angular/core';
import { AppointmentsService } from '../../services/api/appointments/appointments.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CustomEmailValidator } from '../../validators/email.validator';
import { CustomersService } from '../../services/api/customers/customers.service';
import { IAppointmentRequest } from '../../models/interfaces/requests/IAppointmentRequest';
import { AppointmentsCustomerService } from './../../services/other-services/appointments-customer/appointments-customer.service';
import { IAppointmentResponse } from '../../models/interfaces/responses/IAppointmentResponse';

@Component({
  selector: 'app-customers-appointments',
  templateUrl: './customers-appointments.component.html',
  styleUrl: './customers-appointments.component.scss',
})
export class CustomersAppointmentsComponent {
  fb = inject(NonNullableFormBuilder);
  customerForms = this.fb.group({
    email: this.fb.control('', {
      validators: [Validators.required, CustomEmailValidator],
    }),
  });
  appointments: IAppointmentResponse[] = [];
  isLoading: boolean = false;

  constructor(
    private appointmentsServices: AppointmentsService,
    private customersServices: CustomersService,
    private appointmentsCustomerService: AppointmentsCustomerService
  ) {}
  ngOnInit(): void {}

  onSubmit() {
    this.isLoading = true;
    this.appointmentsServices
      .postAppointmentByCustomerEmail(
        this.customerForms.get('email')?.value ?? ''
      )
      .subscribe((appointment) => {
        console.log('appointmentsCustomers', appointment);
        this.appointments = appointment;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
  }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { CustomersService } from './../../services/api/customers/customers.service';
import {
  EmployeesService,
  IEmployeeUpdate,
} from './../../services/api/employees/employees.service';
import { CookieService } from 'ngx-cookie-service';
import { IEmployeeResponse } from '../../models/interfaces/responses/IEmployeeResponse';
import { TimeService } from '../../services/other-services/time/time.service';
import { ToastrService } from 'ngx-toastr';
import { ImageUploadService } from '../../services/other-services/image-upload/image-upload.service';
import { IEmployeeRequest } from '../../models/interfaces/requests/IEmployeeRequest';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  employee: IEmployeeResponse;
  startAt: number = 0;
  finishAt: number = 0;
  base64Image: string =
    typeof window !== 'undefined'
      ? localStorage.getItem('storeImage') ?? ''
      : '';

  constructor(
    private employeesService: EmployeesService,
    private cookie: CookieService,
    private time: TimeService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private imageUploadService: ImageUploadService
  ) {}

  ngOnInit(): void {
    this.employeesService
      .getEmployeeById(this.cookie.get('id'))
      .subscribe((employee: IEmployeeResponse) => {
        this.employee = employee;
        this.cd.detectChanges();
      });
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.imageUploadService
        .convertToBase64(file)
        .then((base64: any) => {
          this.base64Image = base64;
          const data: IEmployeeUpdate = {
            id: this.cookie.get('id'),
            name: this.employee.name,
            email: this.employee.email,
            phone: this.employee.phone,
            cpf: this.employee.cpf,
            startAt: this.employee.startAt,
            finishAt: this.employee.finishAt,
            margin: this.employee.margin,
            skillId: this.employee.skill.id,
            storeId: this.employee.store.id,
            base64Image: this.base64Image,
          };
          this.employeesService.putEmployee(data).subscribe((data) => {
            console.log('data', data);
          });
        })
        .catch((error) => {
          console.error('Error converting file to base64', error);
        });
    }
  }

  convertTime(minutes: number) {
    return this.time.convertMinutesToTime(minutes);
  }

  formatCpf(cpf: string): string {
    if (!cpf) return '';
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  formatPhone(phone: string): string {
    if (!phone) return '';
    phone = phone.replace(/\D/g, '');
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
}

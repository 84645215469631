<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Confirmar Exclusão</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onCancel()"
  ></button>
</div>
<div class="modal-body">
  <p>Tem certeza que deseja deletar?</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="onConfirm()"
  >
    Yes
  </button>
</div>

import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { TimeService } from '../../other-services/time/time.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environments';
import { StoresService } from '../stores/stores.service';
import { ICustomer } from '../../../models/interfaces/registers/ICustomer';
import { EmployeesService } from './../employees/employees.service';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { ICustomerRequest } from '../../../models/interfaces/requests/ICustomerRequest';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private API_URL = environment.API_URL;
  storeId: string = '';
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });
  constructor(
    private http: HttpClient,
    private storeService: StoresService,
    private employeesService: EmployeesService,
    private cookie: CookieService
  ) {}

  getAllCustomers(): Observable<ICustomerResponse[]> {
    return this.http
      .get<any>(`${this.API_URL}/customers`, { headers: this.headers })
      .pipe(
        map((response: any) =>
          response.content.map((customer: ICustomerResponse) => customer)
        )
      );
  }

  getCustomerByEmail(email: string): Observable<ICustomerResponse> {
    return this.http.get<any>(`${this.API_URL}/customers/byEmail/${email}`, { headers: this.headers }).pipe(
      map((response) => {
        const customer = response.content;
        return customer;
      })
    );
  }

  getCustomerById(id: string): Observable<ICustomerResponse> {
    return this.http.get<any>(`${this.API_URL}/customers/${id}`, { headers: this.headers }).pipe(
      map((response) => {
        const customer = response.content;
        return customer;
      })
    );
  }

  postCustomer(customer: ICustomer) {
    console.log('postCustomer', customer)
    return forkJoin({
      stores: this.storeService.getAllStores(),
    }).pipe(
      switchMap((results: any) => {
        console.log(results);
        const requestPayload: ICustomerRequest = {
          name: customer.name,
          email: customer.email,
          phone: customer.phone,
          cpf: customer.cpf,
          base64Image:customer.base64Image,
          storeId: this.cookie.get('storeId'),
          favoriteEmployeeId: customer.favoriteEmployeeId,
          loyaltyPlan: customer.loyaltyPlan,
        };
        console.log('requestPayload',requestPayload);
        return this.http.post<ICustomerRequest>(
          `${this.API_URL}/customers`,
          requestPayload
        );
      })
    );
  }

  putCustomer(customer: ICustomerRequest) {
    return this.http
      .put(`${this.API_URL}/customers`, customer, { headers: this.headers })
      .pipe(
        map((customer: any) => {
          const response = customer.content;
          return response;
        })
      );
  }

  deleteCustomer(id: string) {
    return this.http
      .delete(`${this.API_URL}/customers/${id}`, { headers: this.headers })
      .pipe(map((customer:any) => {
        const response = customer.message;
        return response;
      }));
  }
}

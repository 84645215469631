import { Component, inject } from '@angular/core';
import { ShowSidebarService } from './services/other-services/show-sidebar/show-sidebar.service';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'salao-front';
  private showModalService = inject(ShowSidebarService);
  private cookie = inject(CookieService);
  showModal = false;
  showNavbar: boolean;
  private navigated: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url;
        this.showNavbar = event.url === '/login' || event.url === '/customer-appointments';
        if (currentUrl !== '/customer-appointments' && !this.cookie.get('authToken')) {
          this.router.navigateByUrl('/login');
        } else if (this.cookie.get('skill') && this.cookie.get('skill') !== 'administrator' && !this.navigated) {
          this.router.navigateByUrl('/scheduler');
          this.navigated = true;
        }
      }
    });
    this.showModalService.showSidebar$.subscribe((value) => {
      this.showModal = value;
    });
  }

  closeModal() {
    this.showModal = false;
    this.showModalService.setShowSidebar(this.showModal);
  }
}

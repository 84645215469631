import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from '../../../services/api/customers/customers.service';
import { ToastrService } from 'ngx-toastr';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-customer-register',
  templateUrl: './modal-customer-register.component.html',
  styleUrl: './modal-customer-register.component.scss'
})
export class ModalCustomerRegisterComponent {
  fb = inject(NonNullableFormBuilder);
  customerForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    email: this.fb.control(''),
    phone: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(11)],
    }),
    cpf: this.fb.control(''),
    favoriteEmployeeId: this.fb.control(''),
    loyaltyPlan: this.fb.control(false),
    base64Image: this.fb.control(''),
  });
  employees: IEmployeeResponse[]=[];

  constructor(
    public activeModal: NgbActiveModal,
    private customerService: CustomersService,
    private employeesService: EmployeesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  ngOnInit(): void {
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  onSubmit() {
    this.customerService.postCustomer(this.customerForms.getRawValue()).subscribe(
      (response) => {
        this.toastr.success('Funcionário cadastrado com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success'); // Notifica o outro componente para atualizar a tabela
      },
      (error) => {
        this.toastr.error('Erro ao cadastrar funcionário');
        this.cdr.detectChanges(); // Força a detecção de mudanças em caso de erro
      }
    );
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.customerForms.valid) {
      this.onSubmit();
    }
  }
}

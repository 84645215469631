import { Injectable } from '@angular/core';
import { IEmployee } from '../../../models/interfaces/registers/IEmployee';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import {
  catchError,
  forkJoin,
  map,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { StoresService } from '../stores/stores.service';
import { SkillsService } from '../skills/skills.service';
import { TimeService } from '../../other-services/time/time.service';
import { environment } from '../../../../../environments/environments';
import { IStoreResponse } from '../../../models/interfaces/responses/IStoreResponse';
import { IEmployeeRequest } from '../../../models/interfaces/requests/IEmployeeRequest';
import { CookieService } from 'ngx-cookie-service';

export interface IEmployeeUpdate {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpf: string;
  startAt: number;
  finishAt: number;
  base64Image?: string;
  margin: number;
  storeId: string;
  skillId: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  private API_URL = environment.API_URL;
  skillId: string = '';
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });
  constructor(
    private http: HttpClient,
    private time: TimeService,
    private skillsService: SkillsService,
    private storeService: StoresService,
    private cookie: CookieService
  ) {}

  getAllEmployees(): Observable<IEmployeeResponse[]> {
    return this.http
      .get<any>(`${this.API_URL}/employees`, { headers: this.headers })
      .pipe(
        map((response) =>
          response.content.map((employee: IEmployeeResponse) => ({
            id: employee.id,
            name: employee.name,
            email: employee.email,
            phone: employee.phone,
            cpf: employee.cpf,
            startAt: employee.startAt,
            finishAt: employee.finishAt,
            base64Image: employee.base64Image,
            margin: employee.margin,
            skill: employee.skill,
            store: employee.store,
          }))
        )
      );
  }

  getEmployeeByEmail(
    email: string,
    token: string
  ): Observable<IEmployeeResponse> {
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get<any>(`${this.API_URL}/employees/byEmail/${email}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response.content;
        })
      );
  }

  getEmployeeByPhone(phone: string, token: string) {
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get<any>(`${this.API_URL}/employees/byPhone/${phone}`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(response)
          return response.content;
        })
      );
  }

  getEmployeeById(id: string): Observable<IEmployeeResponse> {
    return this.http
      .get<any>(`${this.API_URL}/employees/${id}`, { headers: this.headers })
      .pipe(
        map((response) => {
          const employee = response.content;
          return {
            id: employee.id,
            name: employee.name,
            email: employee.email,
            phone: employee.phone,
            cpf: employee.cpf,
            startAt: employee.startAt,
            finishAt: employee.finishAt,
            base64Image: employee.base64Image,
            margin: employee.margin,
            skill: employee.skill,
            store: employee.store,
          };
        })
      );
  }

  postEmployee(employee: IEmployee) {
    return forkJoin({
      skill: this.skillsService.getSkillById(employee.skillId),
    }).pipe(
      switchMap((results: any) => {
        const requestPayload: IEmployeeRequest = {
          ...employee,
          startAt: this.time.convertTimeToMinutes(employee.startAt.toString()),
          finishAt: this.time.convertTimeToMinutes(
            employee.finishAt.toString()
          ),
          base64Image: employee.base64Image,
          margin: employee.margin,
          storeId: this.cookie.get('storeId'),
          skillId: results.skill.id,
        };
        return this.http
          .post<IEmployeeRequest>(`${this.API_URL}/employees`, requestPayload)
          .pipe(catchError(this.handleError));
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      errorMessage = `Server-side error: ${error.status}\nMessage: ${error.message}`;
    }
    // Exibe o erro no console
    console.error(errorMessage);
    // Retorna um observable com uma mensagem de erro amigável
    return throwError(() => new Error(errorMessage));
  }

  putEmployee(employee: IEmployeeUpdate) {
    console.log('employeeToUpdate', employee);
    return this.http
      .put(`${this.API_URL}/employees`, employee, { headers: this.headers })
      .pipe(
        map((employee: any) => {
          console.log('resposta', employee);
          const response = employee.content;
          return response;
        })
      );
  }

  put(employee: IEmployeeRequest) {
    console.log('employeeToUpdate', employee);
    return this.http
      .put(`${this.API_URL}/employees`, employee, { headers: this.headers })
      .pipe(
        map((employee: any) => {
          console.log('content', employee.content);
          const response = employee.content;
          return response;
        })
      );
  }

  deleteEmployee(id: string) {
    return this.http
      .delete(`${this.API_URL}/employees/${id}`, { headers: this.headers })
      .pipe(
        map((employee: any) => {
          const response = employee.message;
          return response;
        })
      );
  }
}

import { Component, Input } from '@angular/core';
import { ShowSidebarService } from './../../services/other-services/show-sidebar/show-sidebar.service';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { IButton } from '../../models/interfaces/layouts/navbar/IButton';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  faBars = faBars;
  showModal = false;

  constructor(private showSidebarService: ShowSidebarService) {}

  ngOnInit(): void {
    this.showSidebarService.showSidebar$.subscribe(
      (data) => (this.showModal = data)
    );
  }

  showSideBar() {
    this.showModal = !this.showModal;
    this.showSidebarService.setShowSidebar(this.showModal);
  }
}

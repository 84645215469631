import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ProductsService } from '../products/products.service';
import { StoresService } from '../stores/stores.service';
import { CustomersService } from '../customers/customers.service';
import { EmployeesService } from '../employees/employees.service';
import { environment } from '../../../../../environments/environments';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { ISaleRequest } from '../../../models/interfaces/requests/ISaleRequest';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { IProductResponse } from '../../../models/interfaces/responses/IProductResponse';
import { IAppointmentResponse } from '../../../models/interfaces/responses/IAppointmentResponse';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { ServicesService } from './../services/services.service';

export interface ISaleUpdate {
  id: string;
  customer: ICustomerResponse;
  product: IProductResponse | null;
  employee: IEmployeeResponse;
  appointment: IAppointmentResponse | null;
}

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor(
    private employeesService: EmployeesService,
    private customersService: CustomersService,
    private productsService: ProductsService,
    private servicesService:ServicesService,
    private cookie: CookieService,
    private http: HttpClient
  ) {}

  getAllSales() {
    return this.http.get(`${this.API_URL}/sales`, {
      headers: this.headers,
    }).pipe(
      switchMap((response: any) => {
        const sales = response.content;
        return forkJoin(
          sales.map((sale: any) => {
            const productRequests = sale.productsId.map((productId: string) =>
              this.productsService.getProductById(productId)
            );
            const serviceRequests = sale.servicesId.map((serviceId: string) =>
              this.servicesService.getServiceById(serviceId)
            );
            console.log('miau',serviceRequests)

            return forkJoin({
              products: productRequests.length > 0 ? forkJoin(productRequests) : of(null),
              services: serviceRequests.length > 0 ? forkJoin(serviceRequests) : of(null)
            }).pipe(
              map((result: { products: any; services: any }) => {
                console.log('products', result.products)
                console.log('services', result.services)
                return {
                  ...sale,
                  products: result.products,
                  services: result.services,
                };
              })
            );
          })
        );
      })
    );
  }

  postSale(sale: ISaleRequest) {
    return forkJoin({
      employee: this.employeesService.getEmployeeById(sale.employeeId),
      customer: this.customersService.getCustomerById(sale.customerId),
    }).pipe(
      switchMap((results: any) => {
        const requestPayload: any = {
          ...sale,
          customerId: results.customer.id,
          employeeId: results.employee.id,
        };
        return this.http.post<any>(`${this.API_URL}/sales`, requestPayload, {
          headers: this.headers,
        });
      })
    );
  }

  putSale(sale: ISaleUpdate) {
    return this.http
      .put(`${this.API_URL}/sales`, sale, { headers: this.headers })
      .pipe(
        map((sale: any) => {
          const response = sale.content;
          return response;
        })
      );
  }

  deleteSale(id: string) {
    return this.http
      .delete(`${this.API_URL}/sales/${id}`, { headers: this.headers })
      .pipe(
        map((sale: any) => {
          const response = sale.message;
          return response;
        })
      );
  }

  cancelSale(id: string) {
    console.log(this.headers)
    return this.http
      .put(`${this.API_URL}/sales/cancel/${id}`,{}, { headers: this.headers })
      .pipe(
        map((sale: any) => {
          const response = sale.message;
          return response;
        })
      );
  }
}

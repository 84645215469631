import { NgModule, LOCALE_ID } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BodyComponent } from './components/body/body.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EmployeesComponent } from './views/employees/employees.component';
import { CustomersComponent } from './views/customers/customers.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RegisterCustomerComponent } from './components/registers/register-customer/register-customer.component';
import { RegisterProductComponent } from './components/registers/register-product/register-product.component';
import { LoginComponent } from './views/login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { HomeComponent } from './views/home/home.component';
import { ProductsComponent } from './views/products/products.component';
import { StoresComponent } from './views/stores/stores.component';
import { UpdateEmployeeComponent } from './components/updates/update-employee/update-employee.component';
import { UpdateStoreComponent } from './components/updates/update-store/update-store.component';
import { UpdateCustomerComponent } from './components/updates/update-customer/update-customer.component';
import { UpdateProductComponent } from './components/updates/update-product/update-product.component';
import { RegisterAppointmentComponent } from './components/registers/register-appointment/register-appointment.component';
import { AppointmentsComponent } from './views/appointments/appointments.component';
import { ProfileComponent } from './views/profile/profile.component';
import { SystemSettingsComponent } from './views/system-settings/system-settings.component';
import { ConfigurationsComponent } from './views/system-settings/configurations/configurations.component';
import { ImagesComponent } from './views/system-settings/images/images.component';
import { ThemesComponent } from './views/system-settings/themes/themes.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SalesComponent } from './views/sales/sales.component';
import { RegisterSaleComponent } from './components/registers/register-sale/register-sale.component';
import localePt from '@angular/common/locales/pt';
import { CustomDateFormatPipe } from './pipes/customDateFormat/custom-date-format.pipe';
import { IsoToBrDatePipe } from './pipes/isoToBrDate/iso-to-br-date.pipe';
import { MinutesToTimePipe } from './pipes/minutesToTime/minutes-to-time.pipe';
import { MaskPipe } from './pipes/mask/mask.pipe';
import { DeleteConfirmModalComponent } from './components/delete-confirm-modal/delete-confirm-modal.component';
import { RegisterAddressComponent } from './components/registers/register-address/register-address.component';
import { CustomersAppointmentsComponent } from './views/customers-appointments/customers-appointments.component';
import { CardCustomerAppointmentComponent } from './components/card-customer-appointment/card-customer-appointment.component';
import { EventDetailsModalComponent } from './components/event-details-modal/event-details-modal.component';
import { UpdateSaleComponent } from './components/updates/update-sale/update-sale.component';
import { CardEmployeeComponent } from './components/card-employee/card-employee.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { SkillComponent } from './views/skill/skill.component';
import { CancellationsComponent } from './views/cancellations/cancellations.component';
import { RegisterCancellationsComponent } from './components/registers/register-cancellations/register-cancellations.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { ServicesComponent } from './views/services/services.component';
import { UpdateServiceComponent } from './components/updates/update-service/update-service.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalEmployeeRegisterComponent } from './components/modal-registers/modal-employee-register/modal-employee-register.component';
import { ModalAppointmentRegisterComponent } from './components/modal-registers/modal-appointment-register/modal-appointment-register.component';
import { ModalCustomerRegisterComponent } from './components/modal-registers/modal-customer-register/modal-customer-register.component';
import { ModalProductRegisterComponent } from './components/modal-registers/modal-product-register/modal-product-register.component';
import { ModalSaleRegisterComponent } from './components/modal-registers/modal-sale-register/modal-sale-register.component';
import { ModalServiceRegisterComponent } from './components/modal-registers/modal-service-register/modal-service-register.component';
import { ModalSkillRegisterComponent } from './components/modal-registers/modal-skill-register/modal-skill-register.component';
import { UpdateSkillComponent } from './components/updates/update-skill/update-skill.component';
import { ModalCancelationRegisterComponent } from './components/modal-registers/modal-cancelation-register/modal-cancelation-register.component';

registerLocaleData(localePt, 'pt-BR');

class CustomDateFormatter extends CalendarNativeDateFormatter {
  public override dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    BodyComponent,
    EmployeesComponent,
    CustomersComponent,
    RegisterCustomerComponent,
    RegisterProductComponent,
    LoginComponent,
    HomeComponent,
    ProductsComponent,
    StoresComponent,
    UpdateEmployeeComponent,
    UpdateStoreComponent,
    UpdateCustomerComponent,
    UpdateProductComponent,
    RegisterAppointmentComponent,
    AppointmentsComponent,
    ProfileComponent,
    SystemSettingsComponent,
    ConfigurationsComponent,
    ImagesComponent,
    ThemesComponent,
    SalesComponent,
    RegisterSaleComponent,
    CustomDateFormatPipe,
    IsoToBrDatePipe,
    MinutesToTimePipe,
    MaskPipe,
    DeleteConfirmModalComponent,
    RegisterAddressComponent,
    CustomersAppointmentsComponent,
    CardCustomerAppointmentComponent,
    EventDetailsModalComponent,
    UpdateSaleComponent,
    CardEmployeeComponent,
    DashboardComponent,
    FilterModalComponent,
    SkillComponent,
    CancellationsComponent,
    RegisterCancellationsComponent,
    MultiSelectDropdownComponent,
    ServicesComponent,
    UpdateServiceComponent,
    ModalEmployeeRegisterComponent,
    ModalAppointmentRegisterComponent,
    ModalCustomerRegisterComponent,
    ModalProductRegisterComponent,
    ModalSaleRegisterComponent,
    ModalServiceRegisterComponent,
    ModalSkillRegisterComponent,
    UpdateSkillComponent,
    ModalCancelationRegisterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DecimalPipe,
    FormsModule,
    HttpClientModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgxMaskDirective,
    NgSelectModule,
    NgxMaskPipe,
    FullCalendarModule,
    NgbAccordionModule,
    BaseChartDirective,
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    provideNgxMask(),
    provideCharts(withDefaultRegisterables()),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    CookieService,
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="modal-header">
  <h2 class="modal-title">Cadastrar venda</h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div
    class="modal-body-content"
    style="max-height: 70vh; overflow-y: auto; padding: 10px"
  >
    <div class="content-section">
      <div class="accordion-body">
        <form
          class="d-flex flex-column gap-3"
          [formGroup]="salesForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="employee">Funcionário</label>
            <ng-select
              [items]="employees"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um funcionário"
              formControlName="employeeId"
            />
            <div
              *ngIf="
                salesForm.controls.employeeId.invalid &&
                (salesForm.controls.employeeId.dirty ||
                  salesForm.controls.employeeId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="salesForm.controls.employeeId.errors?.['required']"
              >
                Funcionário é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="customer">Cliente</label>
            <ng-select
              [items]="customers"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um cliente"
              formControlName="customerId"
            />
            <div
              *ngIf="
                salesForm.controls.customerId.invalid &&
                (salesForm.controls.customerId.dirty ||
                  salesForm.controls.customerId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="salesForm.controls.customerId.errors?.['required']"
              >
                Cliente é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                (change)="showScheduler()"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Possui agendamento?</label
              >
            </div>
          </div>
          @if(isScheduled){
          <div class="form-group">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">Início</th>
                  <th scope="col">Término</th>
                  <th scope="col">Produto</th>
                  <th scope="col">Serviço</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data">
                  <td>
                    <input
                      type="radio"
                      name="selection"
                      [value]="item.id"
                      [checked]="item.id === selectedId"
                      (change)="selectLine(item)"
                    />
                  </td>
                  <td>{{ item.startAt | isoToBrDate }}</td>
                  <td>{{ item.concludesAt | isoToBrDate }}</td>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.service.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          }
          <div [ngClass]="{ 'd-none': isScheduled }" class="form-group">
            <div class="form-group">
              <label for="product">Produtos</label>
              <ng-select
                [items]="products"
                [multiple]="true"
                [searchable]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione um produto"
                (change)="onProductsChange($event)"
              >
              </ng-select>
            </div>
            <div class="form-group mt-3">
              <label for="service">Serviços</label>
              <ng-select
                [items]="services"
                [multiple]="true"
                [searchable]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione um serviço"
                (change)="onServiceChange($event)"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="date">Data</label>
            <input
              type="date"
              id="date"
              class="form-control form-control-lg"
              formControlName="date"
              [ngClass]="{
                'is-invalid':
                  salesForm.controls.date.invalid &&
                  (salesForm.controls.date.dirty ||
                    salesForm.controls.date.touched)
              }"
              name="date"
            />
            <div
              *ngIf="
                salesForm.controls.date.invalid &&
                (salesForm.controls.date.dirty ||
                  salesForm.controls.date.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="salesForm.controls.date.errors?.['required']"
              >
                Data é obrigatória
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="paymentMethod">Método de Pagamento</label>
            <ng-select
              [items]="methods"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um método de pagamento"
              formControlName="method"
            />
            <div
              *ngIf="
                salesForm.controls.method.invalid &&
                (salesForm.controls.method.dirty ||
                  salesForm.controls.method.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="salesForm.controls.method.errors?.['required']"
              >
                Método de Pagamento é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="proofOfPayment">Comprovante de Pagamento</label>
            <input
              type="text"
              id="proofOfPayment"
              class="form-control form-control-lg"
              formControlName="proofOfPayment"
              [ngClass]="{
                'is-invalid':
                  salesForm.controls.proofOfPayment.invalid &&
                  (salesForm.controls.proofOfPayment.dirty ||
                    salesForm.controls.proofOfPayment.touched)
              }"
            />
            <div
              *ngIf="
                salesForm.controls.proofOfPayment.invalid &&
                (salesForm.controls.proofOfPayment.dirty ||
                  salesForm.controls.proofOfPayment.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="salesForm.controls.proofOfPayment.errors?.['required']"
              >
                Comprovante de Pagamento é obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="salesForm.reset()"
  >
    Limpar
  </button>
  <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
    Cadastrar
  </button>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  convertTimeToMinutes(time: string) {
    let [hours, minutes] = time.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  }
  convertMinutesToTime(minutes: number) {
    let hours: string | number = Math.floor(minutes / 60);
    let remainingMinutes: string | number = minutes % 60;
    remainingMinutes =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;
    hours = hours < 10 ? '0' + hours : hours;
    return `${hours}:${remainingMinutes}`;
  }
}

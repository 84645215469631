import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { ICustomCalendarEvent } from '../models/interfaces/calendar/ICustomCalendarEvent';
import { EventDetailsModalComponent } from '../components/event-details-modal/event-details-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
    private modalService: NgbModal,
  ) {
    super();
  }

  override month(event: ICustomCalendarEvent): string {
    return `<p><span>Horario: ${this.formatTime(event.start)}</span> - <span>${
      event.end !== undefined ? this.formatTime(event.end) : ''
    }</span> <br> <span>Serviço: ${
      event.title
    }</span> <br> <span>Profissional: ${
      event.extendedProps?.professional || 'Não especificado'
    }</span> <br> <span>Cliente: ${
      event.extendedProps?.client || 'Não especificado'
    }</span>
    <br> <span>Detalhes: ${
      event.extendedProps?.details || 'Não especificado'
    }</span></p>`;
  }

  override week(event: ICustomCalendarEvent): string {
    return `<span>${this.formatTime(event.start)}</span> - <span>${
      event.end !== undefined ? this.formatTime(event.end) : ''
    }</span> <br> <span>Serviço: ${
      event.title
    }</span> <br> <span>Profissional: ${
      event.extendedProps?.professional || 'Não especificado'
    }</span> <br> <span>Cliente: ${
      event.extendedProps?.client || 'Não especificado'
    }</span>`;

  }

  

  override day(event: ICustomCalendarEvent): string {
    return `<span>Horario: ${this.formatTime(event.start)}</span> - <span>${
      event.end !== undefined ? this.formatTime(event.end) : ''
    }</span> <br> <span>Serviço: ${
      event.title
    }</span> <br> <span>Profissional: ${
      event.extendedProps?.professional || 'Não especificado'
    }</span> <br> <span>Cliente: ${
      event.extendedProps?.client || 'Não especificado'
    }</span>`;
  }

  private formatTime(date: Date | undefined): string {
    return this.datePipe.transform(date, 'HH:mm', this.locale) ?? '';
  }
}

<div class="modal-header">
  <h2 class="modal-title">Cadastrar funcionário</h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div
    class="modal-body-content"
    style="max-height: 70vh; overflow-y: auto; padding: 10px"
  >
    <div class="content-section">
      <div class="accordion-body">
        <form
          class="d-flex flex-column gap-3"
          [formGroup]="employeeForms"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="employeeName">Nome</label>
            <input
              type="text"
              id="employeeName"
              class="form-control form-control-lg"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.name.invalid &&
                  (employeeForms.controls.name.dirty ||
                    employeeForms.controls.name.touched)
              }"
            />
            <div
              *ngIf="
                employeeForms.controls.name.invalid &&
                (employeeForms.controls.name.dirty ||
                  employeeForms.controls.name.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="employeeForms.controls.name.errors?.['required']"
              >
                Nome é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="employeeEmail">Email</label>
            <input
              type="email"
              id="employeeEmail"
              class="form-control form-control-lg"
              formControlName="email"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.email.invalid &&
                  (employeeForms.controls.email.dirty ||
                    employeeForms.controls.email.touched)
              }"
            />
            <div
              *ngIf="
                employeeForms.controls.email.invalid &&
                (employeeForms.controls.email.dirty ||
                  employeeForms.controls.email.touched)
              "
              class="invalid-feedback"
            >
              @if(employeeForms.controls.email.errors?.['invalidEmail']){
              <div class="error">Email inválido</div>
              }
            </div>
          </div>
          <div class="form-group">
            <label for="employeePhone">Telefone</label>
            <input
              type="text"
              id="employeePhone"
              class="form-control form-control-lg"
              mask="(00)0 0000-0000"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.phone.invalid &&
                  (employeeForms.controls.phone.dirty ||
                    employeeForms.controls.phone.touched)
              }"
              name="phone"
            />
            <div
              *ngIf="
                employeeForms.controls.phone.invalid &&
                (employeeForms.controls.phone.dirty ||
                  employeeForms.controls.phone.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="employeeForms.controls.phone.errors?.['required']"
              >
                Telefone é obrigatório
              </div>
              <div
                class="error"
                *ngIf="employeeForms.controls.phone.errors?.['minlength']"
              >
                Telefone deve ter pelo menos 11 caracteres
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="employeeCpf">CPF</label>
            <input
              type="text"
              id="employeeCpf"
              mask="000.000.000-00"
              class="form-control form-control-lg"
              formControlName="cpf"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.cpf.invalid &&
                  (employeeForms.controls.cpf.dirty ||
                    employeeForms.controls.cpf.touched)
              }"
              name="cpf"
            />
            <div
              *ngIf="
                employeeForms.controls.cpf.invalid &&
                (employeeForms.controls.cpf.dirty ||
                  employeeForms.controls.cpf.touched)
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="employeeForms.controls.cpf.errors?.['minlength']"
                class="error"
              >
                Cpf deve ter pelo menos 11 caracteres
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="employeepassword">Password</label>
            <input
              type="password"
              id="employeePassword"
              class="form-control form-control-lg"
              formControlName="password"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.password.invalid &&
                  (employeeForms.controls.password.dirty ||
                    employeeForms.controls.password.touched)
              }"
              name="password"
            />
            <div
              *ngIf="
                employeeForms.controls.password.invalid &&
                (employeeForms.controls.password.dirty ||
                  employeeForms.controls.password.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="employeeForms.controls.password.errors?.['required']"
              >
                Senha é obrigatória
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="employeeStartAt">Começa em</label>
            <input
              type="time"
              class="form-control form-control-lg"
              id="startAt"
              placeholder="Começa em"
              formControlName="startAt"
              
            />
          </div>
          <div class="form-group">
            <label for="employeeFinishAt">Termina em</label>
            <input
              type="time"
              id="employeeFinishAt"
              class="form-control form-control-lg"
              formControlName="finishAt"
              name="finishAt"
            />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
            <label for="typeahead-focus">Função</label>
            <ng-select
              [items]="skills"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione uma função"
              formControlName="skillId"
              [ngClass]="{
                'is-invalid':
                  employeeForms.controls.skillId.invalid &&
                  (employeeForms.controls.skillId.dirty ||
                    employeeForms.controls.skillId.touched)
              }"
            />
            <div
              *ngIf="
                employeeForms.controls.skillId.invalid &&
                (employeeForms.controls.skillId.dirty ||
                  employeeForms.controls.skillId.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="employeeForms.controls.skillId.errors?.['required']"
              >
                Selecione uma função
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="employeeForms.reset()"
  >
    Limpar
  </button>
  <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
    Cadastrar
  </button>
</div>

<nav id="sidebar" class="container-fluid">
  <section id="body">
    <div id="logo__box">
      <!-- <span style="font-size: large; font-weight: bold; text-align: center">{{
        storeName
      }}</span> -->
      <fa-icon id="close" (click)="closeModal()" [icon]="faClose"></fa-icon>
    </div>
    <section class="profile__box">
      <!-- @if(!base64Store ||base64Store!== undefined){
      <img
        [src]="base64Store"
        class="border border-1 rounded-circle"
        alt=""
      />
      } @else{
      <i class="bi bi-person-circle text-center" style="font-size: 6rem"></i>
      } -->
      <a href="/profile">Ver Perfil</a>
    </section>
    <section class="nav__itens">
      @if(skill==='administrator'){ @for (route of routesAdmin; track $index) {
      <a
        [href]="route.path"
        class="options__box"
        [ngStyle]="{
          'background-color': routeCurrent === route.path ? '#e9ecef' : '',
          color: routeCurrent === route.path ? '#696cff' : ''
        }"
      >
        <fa-icon [icon]="route.icon" />
        <span>{{ route.label }}</span>
      </a>
      } } @else { @for (route of routesEmployees; track $index) {
      <a
        [href]="route.path"
        class="options__box"
        [ngStyle]="{
          'background-color': routeCurrent === route.path ? '#e9ecef' : '',
          color: routeCurrent === route.path ? '#696cff' : ''
        }"
      >
        <fa-icon [icon]="route.icon" />
        <span>{{ route.label }}</span>
      </a>
      } }
    </section>
  </section>
  <section id="footer">
    <button
      type="button"
      class="btn btn-outline-danger logout__button"
      (click)="logout()"
    >
      <fa-icon [icon]="faArrowRightFromBracket" />Logout
    </button>
  </section>
</nav>

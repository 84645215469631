import { Component } from '@angular/core';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import {
  faChartArea,
  faChartLine,
  faChartPie,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterModalComponent } from '../../components/filter-modal/filter-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  faChartBar = faChartBar;
  faChartArea = faChartArea;
  faChartPie = faChartPie;
  faChartLine = faChartLine;

  // employee filters
  searchText: string = '';
  selectedUser: any = null;
  users = [
    { name: 'Amy Elsner', avatar: 'path/to/avatar1.jpg' },
    { name: 'Anna Fali', avatar: 'path/to/avatar2.jpg' },
    { name: 'Asiya Javayant', avatar: 'path/to/avatar3.jpg' },
    { name: 'Bernardo Dominic', avatar: 'path/to/avatar4.jpg' },
    { name: 'Elwin Sharvill', avatar: 'path/to/avatar5.jpg' }
    // Adicione mais usuários conforme necessário
  ];

  //Bar chat
  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
  };
  barChartLabels: string[] = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
  barChartType: ChartType = 'bar';
  barChartData: ChartData<'bar'> = {
    labels: this.barChartLabels,
    datasets: [
      { data: [45, 37, 60, 70, 46, 33, 50], label: 'Valores recebidos' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Custo de material' },
      { data: [18, 48, 77, 9, 100, 27, 40], label: 'Lucro' }
    ]
  };


  //Line chart
  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
  };
  lineChartLabels: string[] = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
  lineChartType: ChartType = 'line';
  lineChartData: ChartData<'line'> = {
    labels: this.lineChartLabels,
    datasets: [
      { data: [50, 150, 100, 200, 150, 250, 300], label: 'Entrada de caixa', fill: true },
      { data: [30, 100, 70, 140, 110, 170, 200], label: 'Saida de caixa', fill: true }
    ]
  };


  //Pie chart
  pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
  };
  pieChartLabels: string[] = ['USA', 'UK', 'AU'];
  pieChartType: ChartType = 'pie';
  pieChartData: ChartData<'pie'> = {
    labels: this.pieChartLabels,
    datasets: [
      { data: [300, 500, 100], backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'] }
    ]
  };


  //Line chart nofill
  lineChartNoFillOptions: ChartConfiguration['options'] = {
    responsive: true,
    elements: {
      line: {
        fill: false
      }
    }
  };
  lineChartNoFillLabels: string[] = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
  lineChartNoFillType: ChartType = 'line';
  lineChartNoFillData: ChartData<'line'> = {
    labels: this.lineChartNoFillLabels,
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Atendimentos por periodo', borderColor: '#FF6384', backgroundColor: 'transparent' }
    ]
  };

  cards = [
    { title: 'Total de valores recebidos', value: 1234,icon:'bi bi-bar-chart-fill' },
    { title: 'Total de valores gastos', value: 1234,icon:'bi bi-bar-chart-line-fill' },
    { title: 'Total de avaliações', value: 1234,icon:'bi bi-pie-chart-fill' },
    { title: 'Total de procedimentos realizados', value: 1234,icon:'bi bi-graph-up' }
  ];
  salesData = [
    { date: '01 Jan 2045', invoice: 'INV-0123', customer: 'John Doe', amount: 123, status: 'Paid' },
    { date: '02 Jan 2045', invoice: 'INV-0124', customer: 'Jane Doe', amount: 150, status: 'Pending' },
    { date: '03 Jan 2045', invoice: 'INV-0125', customer: 'Jim Doe', amount: 200, status: 'Paid' },
    { date: '04 Jan 2045', invoice: 'INV-0126', customer: 'Jack Doe', amount: 250, status: 'Pending' },
    { date: '05 Jan 2045', invoice: 'INV-0127', customer: 'Jill Doe', amount: 300, status: 'Paid' }
  ];

  constructor(private modalService: NgbModal) {}
  openFilterModal(filterType: string) {
    const modalRef = this.modalService.open(FilterModalComponent, { size: 'lg' });
    modalRef.componentInstance.filterType = filterType;
  }

  filteredUsers() {
    return this.users.filter(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  selectUser(user: any) {
    this.selectedUser = user;
  }
}




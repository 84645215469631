<div class="modal__container" (click)="closeModal()"></div>
<section class="box">
  <i id="close" class="bi bi-x fs-1" (click)="closeModal()"></i>
  <div class="card mb-3" style="min-width: 250px">
    <div class="card-header d-flex align-items-center">
      <i class="bi bi-person-circle" style="font-size: 4rem"></i>
    </div>
    <div class="card-body">
      <p class="card-text">
        <i class="bi bi-calendar"></i>
        Data: {{ event.start | date : "dd/MM/YYYY" }}
      </p>
      <p class="card-text">
        <i class="bi bi-clock"></i>
        Horario: {{ event.start | date : "shortTime" }} -
        {{ event.end | date : "shortTime" }}
      </p>
      <p class="card-text">
        <i class="bi bi-scissors"></i>
        Serviço:
        {{
          event.extendedProps?.service || "Serviço não especificado"
        }}
      </p>
      <p class="card-text">
        <i class="bi bi-currency-dollar"></i>
        Preço:
        {{
          'R$ ' + event.extendedProps?.price
        }}
      </p>
      <p class="card-text">
        <i class="bi bi-person-badge"></i>
        Profissional:
        {{
          event.extendedProps?.professional || "Profissional não especificado"
        }}
      </p>
      <p class="card-text">
        <i class="bi bi-person"></i>
        Cliente: {{ event.extendedProps?.client || "Cliente não especificado" }}
      </p>
      <p class="card-text">
        <i class="bi bi-info-circle"></i>
         Detalhes: {{ event.extendedProps?.details || "Detalhes não especificados" }}
      </p>
      <button
        class="w-100 btn btn-danger btn-lg btn-block"
        (click)="openRegisterModal(event.extendedProps?.appointment)"
      >
        Cancelar atendimento
      </button>
    </div>
  </div>
</section>

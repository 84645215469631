<div class="modal-header">
  <h4 class="modal-title">Apply Filters</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="filterType === 'date'">
    <label for="dateFilter">Select Date:</label>
    <input type="date" id="dateFilter" class="form-control">
  </div>
  <div *ngIf="filterType === 'tag'">
    <label for="tagFilter">Enter Tag:</label>
    <input type="text" id="tagFilter" class="form-control">
  </div>
  <div *ngIf="filterType === 'status'">
    <label for="statusFilter">Select Status:</label>
    <select id="statusFilter" class="form-control">
      <option value="paid">Paid</option>
      <option value="pending">Pending</option>
    </select>
  </div>
  <div *ngIf="filterType === 'amount'">
    <label for="amountFilter">Enter Amount:</label>
    <input type="number" id="amountFilter" class="form-control">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-lg btn-primary" (click)="applyFilters()">Apply</button>
</div>

<div class="modal-header">
    <h2 class="modal-title">Registrar motivo do cancelamento</h2>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <div class="content-section">
        <div class="accordion-body">
          <form
            class="d-flex flex-column gap-3"
            [formGroup]="cancelationForms"
            (ngSubmit)="onSubmit()"
          >
            <div class="form-group">
              <label for="typeahead-focus">Responsável pelo cancelamento</label>
              <ng-select
                [items]="employees"
                [searchable]="true"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione um responsável pelo cancelamento"
                formControlName="responsable"
                [disabled]="true"
                [ngClass]="{
                  'is-invalid':
                    cancelationForms.controls.responsable.invalid &&
                    (cancelationForms.controls.responsable.dirty ||
                      cancelationForms.controls.responsable.touched)
                }"
              />
              <div
                *ngIf="
                  cancelationForms.controls.responsable.invalid &&
                  (cancelationForms.controls.responsable.dirty ||
                    cancelationForms.controls.responsable.touched)
                "
                class="invalid-feedback"
              >
                <div
                  class="error"
                  *ngIf="cancelationForms.controls.responsable.errors?.['required']"
                >
                  Selecione um responsável pelo cancelamento
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="typeahead-focus">Motivo do cancelamento</label>
                  <ng-select
                    [items]="reasons"
                    [searchable]="true"
                    bindLabel="name"
                    bindValue="id"
                    placeholder="Selecione uma função"
                    formControlName="reason"
                    [ngClass]="{
                      'is-invalid':
                        cancelationForms.controls.reason.invalid &&
                        (cancelationForms.controls.reason.dirty ||
                          cancelationForms.controls.reason.touched)
                    }"
                  >
                  </ng-select>
            </div>
            <div
              *ngIf="
                cancelationForms.controls.reason.invalid &&
                (cancelationForms.controls.reason.dirty ||
                  cancelationForms.controls.reason.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="cancelationForms.controls.reason.errors?.['required']"
              >
                Selecione um motivo do cancelamento
              </div>
            </div>
            <div class="form-group">
                <label for="comment" class="form-label">Comentário</label>
                <textarea
                  class="form-control form-control-lg"
                  id="comment"
                  placeholder="Comentário"
                  formControlName="comment"
                  rows="3"
                ></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger btn-lg"
      (click)="activeModal.dismiss('cancel')"
    >
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-secondary btn-lg"
      (click)="cancelationForms.reset()"
    >
      Limpar
    </button>
    <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
      Cadastrar
    </button>
  </div>
  
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { ICustomerResponse } from '../../../models/interfaces/responses/ICustomerResponse';
import { CustomersService } from '../../../services/api/customers/customers.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ICustomerRequest } from '../../../models/interfaces/requests/ICustomerRequest';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
} from 'rxjs';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

export interface ICustomerUpdate {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpf: string;
  storeId: string;
  base64Image: string;
  favoriteEmployeeId: string;
  loyaltyPlan: boolean;
}

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrl: './update-customer.component.scss',
})
export class UpdateCustomerComponent {
  @Input() customer: ICustomerResponse;
  fb = inject(NonNullableFormBuilder);
  cookie = inject(CookieService);
  customerForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    email: this.fb.control(''),
    phone: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(11)],
    }),
    cpf: this.fb.control(''),
    favoriteEmployeeId: this.fb.control(''),
    loyaltyPlan: this.fb.control(false),
    base64Image: this.fb.control(''),
    storeId: this.fb.control(''),
  });
  faClose = faClose;
  startAt: number = 0;
  finishAt: number = 0;
  inputType: string = 'text';
  employees: IEmployeeResponse[] = [];
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(
    public activeModal: NgbActiveModal,
    private customerService: CustomersService,
    private employeesService: EmployeesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  ngOnInit(): void {
    this.customerForms.patchValue(this.customer);
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  update(customer: ICustomerResponse) {
    const customerUpdate: ICustomerUpdate = {
      ...this.customerForms.getRawValue(),
      id: customer.id,
    };
    this.customerService.putCustomer(customerUpdate).subscribe((data: any) => {
      this.toastr.success('Cliente atualizado com sucesso!', 'Sucesso!');
      this.cdr.detectChanges(); // Força a detecção de mudanças
      this.updateTablesService.updateTable();
      this.activeModal.close(data);
    });
  }

  onSelect(event: any) {
    const selectedName = event.item;
    const selectedEmployee = this.employees.find(
      (emp) => emp.name === selectedName
    );
    if (selectedEmployee) {
      this.customer.favoriteEmployee.id = selectedEmployee.id;
    } else {
      this.customer.favoriteEmployee.id = '';
    }
  }

  onFocus() {
    this.inputType = 'time';
  }

  onBlur() {
    this.inputType = 'text';
  }

  timeConverterStartAt(event: any) {
    let time = event.target.value;
    console.log(time);
    this.startAt = this.convertTimeToMinutes(time);
  }

  timeConverterFinishAt(event: any) {
    let time = event.target.value;
    this.finishAt = this.convertTimeToMinutes(time);
  }

  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    console.log(totalMinutes);
    return totalMinutes;
  }
}

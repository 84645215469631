import { Component, inject } from '@angular/core';
import { ICustomerResponse } from '../../models/interfaces/responses/ICustomerResponse';
import { EmployeesService } from '../../services/api/employees/employees.service';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ICustomer } from '../../models/interfaces/registers/ICustomer';
import { CustomersService } from '../../services/api/customers/customers.service';
import { DeleteConfirmModalComponent } from '../../components/delete-confirm-modal/delete-confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UpdateCustomerComponent } from '../../components/updates/update-customer/update-customer.component';
import { ModalCustomerRegisterComponent } from '../../components/modal-registers/modal-customer-register/modal-customer-register.component';
import { UpdateTablesService } from '../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss',
})
export class CustomersComponent {
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  fields: { field: keyof ICustomer; value: string }[] = [
    { field: 'name', value: 'Nome completo' },
    { field: 'email', value: 'Email' },
    { field: 'phone', value: 'Telefone' },
    { field: 'cpf', value: 'Cpf' },
    { field: 'favoriteEmployeeId', value: 'Funcionario favorito' },
  ];
  data: ICustomerResponse[] = [];
  dataSliced: ICustomerResponse[] = [];
  employeesService = inject(EmployeesService);
  customersService = inject(CustomersService);
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  showModal: boolean = false;
  customerToDelete: string = '';
  originData: ICustomerResponse[] = [];

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private updateTablesService: UpdateTablesService
  ) {}

  ngOnInit(): void {
    this.updateTablesService.updateTable$.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  loadData(): void {
    this.customersService
      .getAllCustomers()
      .subscribe((customers: ICustomerResponse[]) => {
        this.data = customers;
        this.originData = [...this.data];
        this.collectionSize = this.data.length;
        this.refreshTable(this.page);
      });
  }

  handleShowModal() {
    this.showModal = true;
    console.log(this.showModal);
  }

  closeModal() {
    this.showModal = false;
  }

  refreshTable(currentPage: number, dataFiltered: ICustomerResponse[] = []) {
    const dataToPaginate = dataFiltered.length ? dataFiltered : this.data;
    const startIndex = (currentPage - 1) * this.pageSize;
    this.dataSliced = dataToPaginate.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  openDeleteModal(employeeId: string): void {
    this.customerToDelete = employeeId;
    this.modalService.open(DeleteConfirmModalComponent).result.then(
      (result) => {
        if (result === 'yes') {
          this.deleteCustomer(this.customerToDelete);
        }
        this.customerToDelete = '';
      },
      (reason) => {
        this.customerToDelete = '';
      }
    );
  }

  openRegisterModal(): void {
    const modalRef = this.modalService.open(ModalCustomerRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Cliente cadastrado com sucesso:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  deleteCustomer(customerId: string): void {
    this.customersService.deleteCustomer(customerId).subscribe((data) => {
      this.toastr.success(data, 'Deletado!');
      window.location.reload();
    });
  }

  onSearchChange(search: Event) {
    const valueFilter = search.target as HTMLInputElement;
    let dataToFilter: ICustomerResponse[] = this.originData;
    const dataFiltered = dataToFilter.filter((data: any) => {
      for (const val of Object.values(data)) {
        if (
          val &&
          val.toString().toLowerCase().includes(valueFilter.value.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    this.data = dataFiltered;
    this.refreshTable(this.page, dataFiltered);
  }

  openEditModal(customer: ICustomerResponse): void {
    const modalRef = this.modalService.open(UpdateCustomerComponent);
    modalRef.componentInstance.customer = customer;

    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Cliente atualizado:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }
}

import { Component, inject } from '@angular/core';
import { IEmployeeResponse } from '../../models/interfaces/responses/IEmployeeResponse';
import {
  EmployeesService,
  IEmployeeUpdate,
} from '../../services/api/employees/employees.service';
import { IEmployee } from '../../models/interfaces/registers/IEmployee';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateEmployeeComponent } from '../../components/updates/update-employee/update-employee.component';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmModalComponent } from '../../components/delete-confirm-modal/delete-confirm-modal.component';
import { ModalEmployeeRegisterComponent } from '../../components/modal-registers/modal-employee-register/modal-employee-register.component';
import { Subscription } from 'rxjs';
import { UpdateTablesService } from '../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss',
})
export class EmployeesComponent {
  private updateTablesService = inject(UpdateTablesService);
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  faSort = faSort;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  fields: { field: keyof IEmployee; value: string }[] = [
    { field: 'name', value: 'Nome Completo' },
    { field: 'email', value: 'E-mail' },
    { field: 'phone', value: 'Telefone' },
    { field: 'cpf', value: 'Cpf' },
    { field: 'startAt', value: 'Começa em' },
    { field: 'finishAt', value: 'Termina em' },
    { field: 'skillId', value: 'Função' },
  ];
  data: IEmployeeResponse[] = [];
  originData: IEmployeeResponse[] = [];
  dataSliced: IEmployeeResponse[] = [];
  employeesService = inject(EmployeesService);
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  showModal: boolean = false;
  sortField: string = '';
  sortDirection: 'asc' | 'desc' | '' = '';
  employeeToDelete: string = '';

  ngOnInit(): void {
    this.updateTablesService.updateTable$.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  loadData() {
    this.employeesService.getAllEmployees().subscribe((employees) => {
      this.data = employees;
      this.originData = [...this.data];
      this.collectionSize = this.data.length;
      this.refreshTable(this.page);
    });
  }

  constructor(private modalService: NgbModal, private toastr: ToastrService) {}

  handleShowModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  openEditModal(employee: IEmployeeResponse): void {
    const modalRef = this.modalService.open(UpdateEmployeeComponent);
    modalRef.componentInstance.employee = employee;

    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Employee updated:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  openRegisterModal(): void {
    const modalRef = this.modalService.open(ModalEmployeeRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Funcionario cadastrado com sucesso:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  refreshTable(currentPage: number, dataFiltered: IEmployeeResponse[] = []) {
    const dataToPaginate = dataFiltered.length ? dataFiltered : this.data;
    const startIndex = (currentPage - 1) * this.pageSize;
    this.dataSliced = dataToPaginate.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  openDeleteModal(employeeId: string): void {
    this.employeeToDelete = employeeId;
    this.modalService.open(DeleteConfirmModalComponent).result.then(
      (result) => {
        if (result === 'yes') {
          this.deleteEmployee(this.employeeToDelete);
        }
        this.employeeToDelete = '';
      },
      (reason) => {
        this.employeeToDelete = '';
      }
    );
  }

  deleteEmployee(employeeId: string): void {
    this.employeesService.deleteEmployee(employeeId).subscribe((data) => {
      this.toastr.success(data, 'Deletado!');
      window.location.reload();
    });
  }

  getFieldValue(employee: IEmployeeResponse, field: string) {
    if (field === 'skill') {
      return employee.skill.name;
    }
    return (employee as any)[field];
  }

  sortData(field: string) {
    if (this.sortField === field) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else if (this.sortDirection === 'desc') {
        this.sortDirection = ''; // Voltar ao estado inicial
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }

    if (this.sortDirection === '') {
      this.data = [...this.originData];
    } else {
      this.data.sort((a, b) => this.compareValues(a, b, field));
    }
    this.refreshTable(this.page);
  }

  compareValues(
    a: IEmployeeResponse,
    b: IEmployeeResponse,
    field: string
  ): number {
    const aValue = this.getFieldValue(a, field);
    const bValue = this.getFieldValue(b, field);

    if (aValue < bValue) {
      return this.sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return this.sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  }

  getNestedValue(object: any, path: string) {
    return path.split('.').reduce((value, key) => value && value[key], object);
  }

  onSearchChange(search: Event) {
    const valueFilter = (search.target as HTMLInputElement).value.toLowerCase();
    let dataToFilter: IEmployeeResponse[] = this.data;
    const dataFiltered = dataToFilter.filter((data: any) => {
      for (const [key, val] of Object.entries(data)) {
        if (
          val &&
          typeof val === 'string' &&
          val.toLowerCase().includes(valueFilter)
        ) {
          return true;
        }
        if (key === 'skill' && val && typeof val === 'object') {
          for (const [nestedKey, nestedVal] of Object.entries(val)) {
            if (
              nestedVal &&
              typeof nestedVal === 'string' &&
              nestedVal.toLowerCase().includes(valueFilter)
            ) {
              return true;
            }
          }
        }
      }
      return false;
    });
    this.dataSliced = dataFiltered;
    this.refreshTable(this.page, this.dataSliced);
  }
}

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col">
          <div
            class="btn-group btn-group-lg"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
              checked
              (change)="setView(CalendarView.Month, 'month')"
            />
            <label class="btn btn-outline-primary" for="btnradio1">Mês</label>

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
              (change)="setView(CalendarView.Week, 'week')"
            />
            <label class="btn btn-outline-primary" for="btnradio2"
              >Semana</label
            >

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio3"
              autocomplete="off"
              (change)="setView(CalendarView.Day, 'day')"
            />
            <label class="btn btn-outline-primary" for="btnradio3">Dia</label>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-3">
            <span>{{
              viewDate | calendarDate : view + "ViewTitle" : "pt-Br"
            }}</span>
            <i
              class="bi bi-caret-left-square-fill text-primary"
              style="
                font-size: 2.875rem;
                cursor: pointer;
                padding: 0 !important;
                margin: 0 !important;
              "
              mwlCalendarPreviousView
              [(viewDate)]="viewDate"
              [view]="view"
            ></i>
            <i
              class="bi bi-caret-right-square-fill text-primary"
              style="font-size: 2.875rem; cursor: pointer"
              mwlCalendarNextView
              [(viewDate)]="viewDate"
              [view]="view"
            ></i>
            <button
              class="btn btn-primary btn-lg"
              mwlCalendarToday
              [(viewDate)]="viewDate"
            >
              Hoje
            </button>
          </div>
          <button
            type="button"
            (click)="openRegisterModal()"
            class="btn btn-lg btn-primary"
          >
            Adicionar agendamento
          </button>
        </div>
      </div>
      <div id="calendar">
        <span [ngSwitch]="view">
          <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="eventClicked($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            [refresh]="refresh"
            locale="pt-BR"
          >
          </mwl-calendar-month-view>

          <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="events"
            [dayStartHour]="7"
            [dayEndHour]="22"
            (eventClicked)="eventClicked($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (dayHeaderClicked)="dayClickedDate($event.day.date)"
            (hourSegmentClicked)="hourClickedDate($event.date)"
            [refresh]="refresh"
            locale="pt-BR"
          >
          </mwl-calendar-week-view>

          <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="events"
            [dayStartHour]="7"
            [dayEndHour]="22"
            (eventClicked)="eventClicked($event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            [refresh]="refresh"
            locale="pt-BR"
          >
          </mwl-calendar-day-view>
        </span>
      </div>
    </div>
  </div>
</div>

@if(isOpen){
<app-event-details-modal [event]="event"></app-event-details-modal>
}

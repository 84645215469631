import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrl: './filter-modal.component.scss'
})
export class FilterModalComponent {
  @Input() filterType: string;

  constructor(public activeModal: NgbActiveModal) {}

  applyFilters() {
    // Lógica para aplicar os filtros aos gráficos
    this.activeModal.close();
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAppointmentResponse } from '../../../models/interfaces/responses/IAppointmentResponse';

@Injectable({
  providedIn: 'root'
})
export class AppointmentCanceledService {
  private showAppointmentsSubject = new Subject<IAppointmentResponse>();
  showAppointments$ = this.showAppointmentsSubject.asObservable();

  setShowAppointments(value: IAppointmentResponse): void {
    this.showAppointmentsSubject.next(value);
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: './delete-confirm-modal.component.html',
  styleUrl: './delete-confirm-modal.component.scss'
})
export class DeleteConfirmModalComponent {
  @Output() confirmDelete = new EventEmitter<void>();

  constructor(public activeModal: NgbActiveModal) {}

  onConfirm(): void {
    this.confirmDelete.emit();
    this.activeModal.close('yes');
  }

  onCancel(): void {
    this.activeModal.dismiss('cancel');
  }
}

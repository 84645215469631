import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAppointmentResponse } from '../../../models/interfaces/responses/IAppointmentResponse';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsCustomerService {
  private showCustomerSubject = new Subject<IAppointmentResponse>();
  showCustomer$ = this.showCustomerSubject.asObservable();

  setShowAppointmentCustomer(value: IAppointmentResponse): void {
    this.showCustomerSubject.next(value);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IStoreResponse } from '../../../models/interfaces/responses/IStoreResponse';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });
  constructor(private http: HttpClient, private cookie: CookieService) {}

  getStoreId(storeId: string) {
    return this.http
      .get<any>(`${this.API_URL}/stores/${storeId}`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const store = response.content;
          return store;
        })
      );
  }

  getAllStores() {
    return this.http.get(`${this.API_URL}/stores`, { headers: this.headers });
  }

  putStore(store: any) {
    console.log('data vinda', store);
    return this.http
      .put(`${this.API_URL}/stores`, store, { headers: this.headers })
      .pipe(
        map((store: any) => {
          console.log('resposta', store);
          const response: IStoreResponse = store.content;
          return response;
        })
      );
  }
}

import { Component, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IEmployeeResponse } from '../../../models/interfaces/responses/IEmployeeResponse';
import {
  EmployeesService,
  IEmployeeUpdate,
} from './../../../services/api/employees/employees.service';
import { ToastrService } from 'ngx-toastr';
import { ISkillResponse } from '../../../models/interfaces/responses/ISkillResponse';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
} from 'rxjs';
import { SkillsService } from '../../../services/api/skills/skills.service';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrl: './update-employee.component.scss',
})
export class UpdateEmployeeComponent {
  @Input() employee: IEmployeeResponse;
  faClose = faClose;
  startAt: number = 0;
  finishAt: number = 0;
  inputType: string = 'text';
  focusSkill$ = new Subject<string>();
  clickSkill$ = new Subject<string>();
  skillId: string = '';
  skills: ISkillResponse[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private employeeService: EmployeesService,
    private skillService: SkillsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.skillService.getAllSkills().subscribe((skills) => {
      this.skills = skills;
    });
  }

  update(employee: IEmployeeResponse) {
    const employeeUpdate: IEmployeeUpdate = {
      id: employee.id,
      name: employee.name,
      email: employee.email,
      cpf: employee.cpf,
      base64Image: '',
      startAt: this.startAt !== 0 ? this.startAt : employee.startAt,
      finishAt: this.finishAt !== 0 ? this.finishAt : employee.finishAt,
      margin: employee.margin,
      phone: employee.phone,
      skillId: employee.skill.id,
      storeId: employee.store.id,
    };
    this.employeeService.putEmployee(employeeUpdate).subscribe((data: any) => {
      this.toastr.success('Funcionario atualizado com sucesso!', 'Sucesso!');
      window.location.reload();
      this.activeModal.close(employee);
    });
  }

  onSelect(event: any) {
    const selectedName = event.item;
    const selectedSkill = this.skills.find(
      (skill) => skill.name === selectedName
    );
    if (selectedSkill) {
      this.employee.skill.id = selectedSkill.id;
    } else this.employee.skill.id = '';
  }

  onFocus() {
    this.inputType = 'time';
  }

  onBlur() {
    this.inputType = 'text';
  }

  timeConverterStartAt(event: any) {
    let time = event.target.value;
    console.log(time);
    this.startAt = this.convertTimeToMinutes(time);
  }

  timeConverterFinishAt(event: any) {
    let time = event.target.value;
    this.finishAt = this.convertTimeToMinutes(time);
  }

  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    console.log(totalMinutes);
    return totalMinutes;
  }
}

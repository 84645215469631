import { Component } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
} from 'angular-calendar';
import { from, lastValueFrom, map, mergeMap, of, Subject, toArray } from 'rxjs';
import { CustomEventTitleFormatter } from '../../validators/custom-event-title-formatter.provider';
import { ICustomCalendarEvent } from '../../models/interfaces/calendar/ICustomCalendarEvent';
import { ToastrService } from 'ngx-toastr';
import { isSameDay, isSameMonth } from 'date-fns';
import { AppointmentsService } from '../../services/api/appointments/appointments.service';
import { CookieService } from 'ngx-cookie-service';
import { api } from '../../configs/api/api';
import { EventDetailsModalComponent } from '../../components/event-details-modal/event-details-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAppointmentResponse } from './../../models/interfaces/responses/IAppointmentResponse';
import {
  ModalAppointmentsService,
  IShowModalAppointment,
} from './../../services/other-services/modal-appointments/modal-appointments.service';
import { ModalAppointmentRegisterComponent } from '../../components/modal-registers/modal-appointment-register/modal-appointment-register.component';
import { UpdateTablesService } from '../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrl: './appointments.component.scss',
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class AppointmentsComponent {
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  isOpen: boolean = false;
  event: ICustomCalendarEvent;
  CalendarView = CalendarView;
  events: ICustomCalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  refresh = new Subject<void>();
  initialDate: Date | string = new Date();
  isValidInitialDate: boolean = true;
  isValidFinalDate: boolean = true;
  appointments: any[] = [];
  finalDate: Date | string = '';
  currentView: 'month' | 'week' | 'day' = 'month';

  constructor(
    private toastr: ToastrService,
    private appointmentsService: AppointmentsService,
    private cookie: CookieService,
    private modalService: NgbModal,
    private updateTablesService: UpdateTablesService,
    private modalAppointments: ModalAppointmentsService
  ) {}

  ngOnInit() {
    this.modalAppointments.showModalAppointments$.subscribe((isOpen) => {
      this.isOpen = isOpen;
    });
    let month: number = this.viewDate.getMonth();
    let humanReadableMonth: number = month + 1;
    this.updateTablesService.updateTable$.subscribe(() => {
      this.loadAppointments(humanReadableMonth);
    });
    this.loadAppointments(humanReadableMonth);
    console.log('events', this.events);
  }

  loadAppointments(month: number): void {
    if (this.cookie.get('skill') === 'administrator') {
      this.appointmentsService.getAllAppointments().subscribe((events: any) => {
        console.log(events);
        this.events = events;
      });
    } else {
      this.appointmentsService
        .postAppointmentByEmployee(this.cookie.get('id'), month)
        .subscribe((events: any) => {
          this.events = events;
        });
    }
  }

  openRegisterModal(): void {
    const modalRef = this.modalService.open(ModalAppointmentRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Cliente cadastrado com sucesso:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  setView(view: CalendarView, type: 'month' | 'week' | 'day') {
    this.view = view;
    this.currentView = type;
  }

  validateInitialDate(event: Event) {
    const ev = event.target as HTMLInputElement;
    const date = new Date(ev.value);
    console.log(date);
    if (!date) {
      this.isValidInitialDate = false;
    } else {
      this.isValidInitialDate = true;
    }
    const now = new Date();
    if (date < now) {
      this.isValidInitialDate = false;
    } else {
      this.isValidInitialDate = true;
    }
  }

  validateFinalDate(event: Event) {
    const ev = event.target as HTMLInputElement;
    const date = new Date(ev.value);
    if (!date) {
      this.isValidFinalDate = false;
    } else {
      this.isValidFinalDate = true;
    }
    const now = new Date();
    const initialDate = new Date(this.initialDate);
    if (date < now || date < initialDate) {
      this.isValidFinalDate = false;
    } else {
      this.isValidFinalDate = true;
    }
  }

  handleInitialDateChange(event: Event) {
    const ev = event.target as HTMLInputElement;
  }

  handleFinalDateChange(event: Event) {
    const ev = event.target as HTMLInputElement;
    this.finalDate = ev.value;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }) {
    if (isSameMonth(date, this.viewDate)) {
      if (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  dayClickedDate(date: Date) {
    this.view = CalendarView.Day;
    this.viewDate = date;
    this.currentView = 'day';
  }

  hourClickedDate(date: Date) {
    this.initialDate = this.formatDate(date.toISOString());
    this.finalDate = this.formatDate(date.toISOString(), true);
    this.isValidInitialDate = true;
    this.isValidFinalDate = true;
  }

  eventClicked(event: any) {
    this.isOpen = true;
    this.event = event.event;
  }

  formatDate(dateString: string, isFinalDate: boolean = false) {
    const date = new Date(dateString);
    date.setHours(date.getHours() - 3);
    if (isFinalDate) {
      date.setHours(0 - 3);
      date.setMinutes(0);
    }
    return date.toISOString().slice(0, 16);
  }

  eventTimesChanged(event: CalendarEventTimesChangedEvent<any>) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (event.newStart < currentDate) {
      console.error('O evento não pode começar antes do dia atual.');
      return;
    } else {
      event.event.start = event.newStart;
      event.event.end = event.newEnd;
      this.refresh.next();
    }
  }
}

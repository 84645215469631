import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { IProductUpdate } from '../products/products.service';
import { IProductResponse } from '../../../models/interfaces/responses/IProductResponse';
import { IProduct } from '../../../models/interfaces/registers/IProduct';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private API_URL = environment.API_URL;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.cookie.get('authToken')}`,
  });

  constructor(private http: HttpClient, private cookie: CookieService) {}

  getAllServices(): Observable<IProductResponse[]> {
    return this.http
      .get<any>(`${this.API_URL}/services`, { headers: this.headers })
      .pipe(
        map((response: any) =>
          response.content.map((services: IProductResponse) => services)
        )
      );
  }

  getServiceById(id: string): Observable<IProductResponse> {
    if(id =="") {
      console.log('psjdgjçkdsjgkçldfjg')
    }
    return this.http
      .get<any>(`${this.API_URL}/services/${id}`, { headers: this.headers })
      .pipe(
        map((response: any) => {
          const service = response.content;
          return service;
        })
      );
  }

  postService(service: IProduct) {
    let newService: IProduct = {
      ...service,
      storeId: this.cookie.get('storeId'),
    };
    return this.http.post<IProductResponse>(
      `${this.API_URL}/services`,
      newService
    );
  }

  putService(service: IProductUpdate) {
    return this.http
      .put(`${this.API_URL}/services`, service, { headers: this.headers })
      .pipe(
        map((service: any) => {
          const response = service.content;
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      errorMessage = `Server-side error: ${error.status}\nMessage: ${error.message}`;
    }
    // Exibe o erro no console
    console.error(errorMessage);
    // Retorna um observable com uma mensagem de erro amigável
    return throwError(() => new Error(errorMessage));
  }

  deleteService(id: string) {
    return this.http
      .delete(`${this.API_URL}/services/${id}`, { headers: this.headers })
      .pipe(
        map((service: any) => {
          const response = service.message;
          return response;
        })
      );
  }
}

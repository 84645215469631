import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoToBrDate'
})
export class IsoToBrDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value:string | Date): string {
    return this.datePipe.transform(value, 'dd/MM/yyyy HH:mm', 'pt-BR') ?? '';
  }
}

import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { SkillsService } from '../../../services/api/skills/skills.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-skill-register',
  templateUrl: './modal-skill-register.component.html',
  styleUrl: './modal-skill-register.component.scss',
})
export class ModalSkillRegisterComponent {
  fb = inject(NonNullableFormBuilder);
  cdr = inject(ChangeDetectorRef);
  updateTablesService = inject(UpdateTablesService);
  cookie = inject(CookieService);
  skillForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    storeId: this.fb.control(this.cookie.get('storeId')),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private skillService: SkillsService,
    private toastr: ToastrService
  ) {}

  onSubmit() {
    this.skillService
      .postSkill(this.skillForms.getRawValue())
      .subscribe((response) => {
        this.toastr.success('Função cadastrada com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.close();
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.skillForms.valid) {
      this.onSubmit();
    }
  }
}

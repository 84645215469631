import { Component, Input } from '@angular/core';
import { IAppointmentRequest } from '../../models/interfaces/requests/IAppointmentRequest';
import { IAppointmentResponse } from '../../models/interfaces/responses/IAppointmentResponse';

@Component({
  selector: 'app-card-customer-appointment',
  templateUrl: './card-customer-appointment.component.html',
  styleUrl: './card-customer-appointment.component.scss',
})
export class CardCustomerAppointmentComponent {
  @Input() appointment: IAppointmentResponse;
  profilePic = 'assets/profile-pic.jpg';
  service = 'Corte de Cabelo';
  professionalName = 'João Silva';
  startTime = '10:00 AM';
  endTime = '11:00 AM';

  ngOnInit(): void {
    console.log('appointmentssss',this.appointment)
  }
}

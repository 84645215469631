<div class="modal-header">
  <h2 class="modal-title">Cadastrar cliente</h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div
    class="modal-body-content"
  >
    <div class="content-section">
      <div class="accordion-body">
        <form
          class="d-flex flex-column gap-3"
          [formGroup]="customerForms"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="customerName">Nome</label>
            <input
              type="text"
              id="customerName"
              class="form-control form-control-lg"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  customerForms.controls.name.invalid &&
                  (customerForms.controls.name.dirty ||
                    customerForms.controls.name.touched)
              }"
            />
            <div
              *ngIf="
                customerForms.controls.name.invalid &&
                (customerForms.controls.name.dirty ||
                  customerForms.controls.name.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="customerForms.controls.name.errors?.['required']"
              >
                Nome é obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="customerEmail">Email</label>
            <input
              type="email"
              id="customerEmail"
              class="form-control form-control-lg"
              formControlName="email"
            />
          </div>
          <div class="form-group">
            <label for="customerPhone">Telefone</label>
            <input
              type="text"
              id="customerPhone"
              class="form-control form-control-lg"
              mask="(00)0 0000-0000"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                  customerForms.controls.phone.invalid &&
                  (customerForms.controls.phone.dirty ||
                    customerForms.controls.phone.touched)
              }"
              name="phone"
            />
            <div
              *ngIf="
                customerForms.controls.phone.invalid &&
                (customerForms.controls.phone.dirty ||
                  customerForms.controls.phone.touched)
              "
              class="invalid-feedback"
            >
              <div
                class="error"
                *ngIf="customerForms.controls.phone.errors?.['required']"
              >
                Telefone é obrigatório
              </div>
              <div
                class="error"
                *ngIf="customerForms.controls.phone.errors?.['minlength']"
              >
                Telefone deve ter pelo menos 11 caracteres
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="customerCpf">CPF</label>
            <input
              type="text"
              id="customerCpf"
              mask="000.000.000-00"
              class="form-control form-control-lg"
              formControlName="cpf"
            />
          </div>
          <div class="form-group">
            <label for="customerFavoriteEmployee">Funcionário favorito</label>
            <ng-select
              [items]="employees"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um funcionário"
              formControlName="favoriteEmployeeId"
            />
          </div>
          <div class="form-group">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                formControlName="loyaltyPlan"
                id="flexSwitchCheckDefault"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Plano Fidelidade</label
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="customerForms.reset()"
  >
    Limpar
  </button>
  <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">
    Cadastrar
  </button>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card p-4 max-height-col">
        <h2 class="mb-4">Configurações da Loja</h2>
        <form>
          <!-- Nome da Loja -->
          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="socialReasonStore" class="col-sm-3 col-lg-3 col-form-label">Razão social</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="socialReasonStore" [(ngModel)]="socialReason" name="socialReason">
            </div>
          </div>

          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="storeName" class="col-sm-3 col-lg-3 col-form-label">Nome da
              loja</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="storeName" [(ngModel)]="fantasyName" name="fantasyName">
            </div>
          </div>

          <!-- Endereço da Loja -->
          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="ufAddress" class="col-sm-3 col-lg-3 col-form-label">Estado</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="ufAddress" [(ngModel)]="uf" name="uf">
            </div>
          </div>

          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="cityAddress" class="col-sm-3 col-lg-3 col-form-label">Cidade</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="cityAddress" [(ngModel)]="city" name="city">
            </div>
          </div>

          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="storeAddress" class="col-sm-3 col-lg-3 col-form-label">Endereço
              da loja</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="storeAddress" [(ngModel)]="street" name="street">
            </div>
          </div>

          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="cnpj" class="col-sm-3 col-lg-3 col-form-label">Cnpj</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="cnpj" [(ngModel)]="cnpj" name="cnpj" disabled>
            </div>
          </div>
          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="ufSubscribe" class="col-sm-3 col-lg-3 col-form-label">Inscrição estadual</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="ufSubscribe" [(ngModel)]="ufSubscribe" name="ufSubscribe" disabled>
            </div>
          </div>
          <div class="form-group form-group-lg row mb-4 align-items-center">
            <label for="citySubscribe" class="col-sm-3 col-lg-3 col-form-label">Inscrição municipal</label>
            <div class="col-sm-9 col-lg-5">
              <input type="text" class="form-control form-control-lg"
                id="citySubscribe" [(ngModel)]="citySubscribe" name="citySubscribe">
            </div>
          </div>

          <!-- Botão de Submissão -->
          <div class="form-group form-group-lg row">
            <div class="col-sm-9 col-lg-5 offset-sm-3">
              <button type="submit" class="btn btn-lg btn-primary" (click)="updateConfigurations()">Salvar
                Configurações</button>
            </div>
          </div>
        </form>
        <!-- <app-images /> -->
        <!-- <app-themes /> -->
      </div>
    </div>
  </div>
</div>

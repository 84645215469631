<main class="container-fluid" style="height: 100vh;">
  <div class="row navbar__container">
    @if(showModal) {
    <div class="sidebar__container active">
      <app-sidebar></app-sidebar>
    </div>
    <section class="modal__container" (click)="closeModal()"></section>
    } @else {
    <div class="col px-lg-4 px-md-1">
      @if(!showNavbar) {
      <app-navbar></app-navbar>
      }
    </div>
    }
  </div>
  <router-outlet></router-outlet>
</main>

import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from '../../../services/api/employees/employees.service';
import { SkillsService } from '../../../services/api/skills/skills.service';
import { ToastrService } from 'ngx-toastr';
import { ISkillResponse } from '../../../models/interfaces/responses/ISkillResponse';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CustomEmailValidator } from '../../../validators/email.validator';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-employee-register',
  templateUrl: './modal-employee-register.component.html',
  styleUrl: './modal-employee-register.component.scss',
})
export class ModalEmployeeRegisterComponent {
  skills: ISkillResponse[] = [];
  fb = inject(NonNullableFormBuilder);
  private cdr = inject(ChangeDetectorRef);
  private updateTablesService = inject(UpdateTablesService);
  employeeForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    email: this.fb.control(''),
    password: this.fb.control('', {
      validators: [Validators.required],
    }),
    phone: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(11)],
    }),
    cpf: this.fb.control('', {
      validators: [Validators.minLength(11)],
    }),
    startAt: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(4)],
    }),
    finishAt: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(4)],
    }),
    margin: this.fb.control(0, {
      validators: [Validators.required],
    }),
    skillId: this.fb.control('', {
      validators: [Validators.required],
    }),
    base64Image: this.fb.control(''),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private employeeService: EmployeesService,
    private skillService: SkillsService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.skillService.getAllSkills().subscribe((skills) => {
      this.skills = skills;
    });
  }

  onSubmit() {
    this.employeeService.postEmployee(this.employeeForms.getRawValue()).subscribe(
      (response) => {
        this.toastr.success('Funcionário cadastrado com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success');
      },
      (error) => {
        this.toastr.error('Erro ao cadastrar funcionário');
        this.cdr.detectChanges(); // Força a detecção de mudanças em caso de erro
      }
    );
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.employeeForms.valid) {
      this.onSubmit();
    }
  }
}

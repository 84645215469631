import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IProductUpdate, ProductsService } from '../../../services/api/products/products.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IProductResponse } from './../../../models/interfaces/responses/IProductResponse';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrl: './update-product.component.scss'
})
export class UpdateProductComponent {
  @Input() product: IProductResponse;
  faClose = faClose;

  constructor(
    public activeModal: NgbActiveModal,
    private productService: ProductsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  update(product: IProductResponse) {
    const productUpdate: IProductUpdate = {
      id: product.id,
      name: product.name,
      description:product.description,
      price: product.price,
    };
    this.productService.putProduct(productUpdate).subscribe((data: any) => {
      this.toastr.success('Produto atualizado com sucesso!', 'Sucesso!');
      this.cdr.detectChanges(); // Força a detecção de mudanças
      this.updateTablesService.updateTable();
      this.activeModal.close(product);  
    });
  }
}

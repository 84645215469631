import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ISkillRequest } from '../../../models/interfaces/requests/ISkillRequest';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISkillUpdate, SkillsService } from '../../../services/api/skills/skills.service';
import { ToastrService } from 'ngx-toastr';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-update-skill',
  templateUrl: './update-skill.component.html',
  styleUrl: './update-skill.component.scss'
})
export class UpdateSkillComponent {
  @Input() skill: ISkillUpdate;
  faClose = faClose;

  constructor(
    public activeModal: NgbActiveModal,
    private skillService: SkillsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  update(skill: ISkillUpdate) {
    this.skillService.putSkill(skill).subscribe((data: any) => {
      this.toastr.success('Função atualizada com sucesso!', 'Sucesso!');
      this.cdr.detectChanges(); // Força a detecção de mudanças
      this.updateTablesService.updateTable();
      this.activeModal.close(skill);
    });
  }
}

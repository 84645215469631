<div class="card mb-3 w-100">
  <div class="d-flex">
    <div class="date text-center">
      <span class="month">{{ appointment.startAt | date : "MMM" }}</span>
      <span class="day">{{ appointment.startAt | date : "dd" }}</span>
      <span class="weekday">{{ appointment.startAt | date : "EEE" }}</span>
    </div>
    <div class="card-body p-2">
      <div class="d-flex align-items-center justify-content-between px-3">
        @if(appointment.employee.base64Image!=='default' &&
        appointment.employee.base64Image!==''){
        <img
          [src]="appointment.employee.base64Image"
          class="border border-1 rounded-circle"
          alt=""
          srcset=""
        />
        } @else{
        <i class="bi bi-person-circle text-center" style="font-size: 8rem"></i>
        }
        <div class="d-flex flex-column p-3">
          <p class="card-text">
            Horário: {{ appointment.startAt | isoToBrDate }} -
            {{ appointment.concludesAt | isoToBrDate }}
          </p>
          <p class="card-text">Profissional: {{ appointment.customer.name }}</p>
          <p class="card-text">Serviço: {{ appointment.service.name }}</p>
          <p class="card-text">Preço: {{ appointment.service.price }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

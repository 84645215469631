import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICustomCalendarEvent } from '../../../models/interfaces/calendar/ICustomCalendarEvent';

export interface IShowModalAppointment {
  appointment: ICustomCalendarEvent;
  isOpen: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalAppointmentsService {
  private showModalAppointmentsSubject = new Subject<boolean>();
  showModalAppointments$ = this.showModalAppointmentsSubject.asObservable();

  setShowModalAppointments(value: boolean): void {
    this.showModalAppointmentsSubject.next(value);
  }
}

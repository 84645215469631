import { Component, HostListener, inject } from '@angular/core';
import { response } from 'express';
import { AuthGuardService } from './../../services/auth/auth-guard/auth-guard.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CustomEmailValidator } from '../../validators/email.validator';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EmployeesService } from '../../services/api/employees/employees.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  fb = inject(NonNullableFormBuilder);
  isLoading: boolean = false;
  loginForm = this.fb.group({
    username: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(11)],
    }),
    password: this.fb.control('', {
      validators: [Validators.required],
    }),
  });

  constructor(
    private authGuard: AuthGuardService,
    private employeeService: EmployeesService,
    private toastr: ToastrService,
    private router: Router,
    private cookie: CookieService
  ) {}

  onSubmit() {
    this.isLoading = true;
    this.authGuard.getAuthToken(this.loginForm.getRawValue()).subscribe(
      (response: any) => {
        console.log(response.content);
        this.cookie.set('authToken', response.content);
        this.employeeService
          .getEmployeeByPhone(
            this.loginForm.get('username')?.value ?? '',
            response.content
          )
          .subscribe((employee) => {
            this.cookie.set('storeId',employee.store.id)
            this.cookie.set('id', employee.id);
            this.cookie.set('skill', employee.skill.name.toLowerCase());
            this.toastr.success('Usuario autenticado com sucesso!', 'Sucesso!');
            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
            this.router.navigateByUrl('/scheduler');
          });
      },
      (error) => {
        this.toastr.error('Email ou senha estão incorretos', 'Erro!');
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        this.cookie.delete('id');
        this.cookie.delete('skill');
        this.cookie.delete('authToken');
      }
    );
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.loginForm.valid) {
      this.onSubmit();
    }
  }
}

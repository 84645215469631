import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IShowModalAppointment,
  ModalAppointmentsService,
} from '../../services/other-services/modal-appointments/modal-appointments.service';
import { ICustomCalendarEvent } from '../../models/interfaces/calendar/ICustomCalendarEvent';
import { AppointmentsService } from '../../services/api/appointments/appointments.service';
import { CancellationsService } from '../../services/api/cancellations/cancellations.service';
import { ICancellationRequest } from '../../models/interfaces/requests/ICancellationRequest';
import { IAppointmentResponse } from '../../models/interfaces/responses/IAppointmentResponse';
import { AppointmentCanceledService } from '../../services/other-services/appointment-canceled/appointment-canceled.service';
import { Router } from '@angular/router';
import { ModalCancelationRegisterComponent } from '../modal-registers/modal-cancelation-register/modal-cancelation-register.component';

@Component({
  selector: 'app-event-details-modal',
  templateUrl: './event-details-modal.component.html',
  styleUrl: './event-details-modal.component.scss',
})
export class EventDetailsModalComponent {
  @Input() event: ICustomCalendarEvent;
  private appointmentService = inject(AppointmentsService);
  private modalService = inject(NgbModal);
  private cancellationsService = inject(CancellationsService);
  private appointmentCanceled = inject(AppointmentCanceledService);
  private router = inject(Router);

  constructor(private modalAppointments: ModalAppointmentsService) {}
  ngOnInit(): void {
    console.log('evento vindo', this.event);
  }

  closeModal() {
    this.modalAppointments.setShowModalAppointments(false);
  }

  openRegisterModal(appointment: IAppointmentResponse | undefined): void {
    console.log('appointment', appointment);
    if (appointment) {
      const modalRef = this.modalService.open(ModalCancelationRegisterComponent, {
        backdrop: 'static',
        keyboard: false,
      scrollable: true,
    });
    modalRef.componentInstance.appointment = appointment;
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Agendamento cancelado com sucesso:', result);
        }
      })
      .catch((error) => {
          console.log('Modal descartado com:', error);
        });
    }
  }
}

<section class="login__box">
  <section
    class="container d-flex justify-content-center align-items-center w-100 h-100"
  >
    <div class="card p-4 shadow-sm" style="width: 100%; max-width: 400px">
      <h3 class="card-title text-center mb-4">Login</h3>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="phone" class="form-label">Telefone</label>
          <input
            type="text"
            id="phone"
            formControlName="username"
            mask="(00) 0 0000-0000"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid':
                loginForm.controls.username.invalid &&
                (loginForm.controls.username.dirty ||
                  loginForm.controls.username.touched)
            }"
          />
          <div
            *ngIf="
              loginForm.controls.username.invalid &&
              (loginForm.controls.username.dirty ||
                loginForm.controls.username.touched)
            "
            class="invalid-feedback"
          >
            <div
              *ngIf="loginForm.controls.username.errors?.['required']"
              class="error"
            >
              Telefone é obrigatório
            </div>
            <div
              *ngIf="loginForm.controls.username.errors?.['minlength']"
              class="error"
            >
              Telefone deve ter pelo menos 11 caracteres
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            id="password"
            formControlName="password"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid':
                loginForm.controls.password.invalid &&
                (loginForm.controls.password.dirty ||
                  loginForm.controls.password.touched)
            }"
          />
          <div
            *ngIf="
              loginForm.controls.password.invalid &&
              (loginForm.controls.password.dirty ||
                loginForm.controls.password.touched)
            "
            class="invalid-feedback"
          >
            <div
              *ngIf="loginForm.controls.password.errors?.['required']"
              class="error"
            >
              Senha é obrigatória
            </div>
          </div>
        </div>
        <div class="d-grid mb-5">
          <button
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
            class="btn btn-lg btn-primary"
          >
            @if(isLoading){
            <span
              class="spinner-border spinner-border-md"
              role="status"
              aria-hidden="true"
            ></span>
            Loading... } @else{ Login }
          </button>
        </div>
        <a href="/customer-appointments" class="link-primary" style="cursor: pointer;">Entre como usuario</a>
      </form>
    </div>
  </section>
</section>

<section class="container mt-5">
  <section class="row justify-content-center">
    <div class="col-md-8 col-lg-6">
      <div class="card bg-white">
        <div class="card-header d-flex gap-3 align-items-center">
          <!-- <img
            [src]="base64Image"
            class="border border-1 rounded-circle"
            alt=""
          /> -->
          <!-- <button
            class="btn btn-primary btn-lg upload-btn"
            (click)="fileInput.click()"
          >
            Atualizar Foto
          </button>
          <input
            type="file"
            (change)="onFileSelected($event)"
            #fileInput
            hidden
          /> -->
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Nome:</span>
            <span class="col-7">{{ employee.name }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Email:</span>
            <span class="col-7">{{ employee.email }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Telefone:</span>
            <span class="col-7">{{ formatPhone(employee.phone) }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Cpf:</span>
            <span class="col-7">{{ formatCpf(employee.cpf) }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Inicio do Expediente:</span>
            <span class="col-7">{{ convertTime(employee.startAt) }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-5 font-weight-bold">Final do Expediente:</span>
            <span class="col-7">{{ convertTime(employee.finishAt) }}</span>
          </div>
        </div>
        <div class="card-footer text-right">
          <button class="btn btn-lg btn-primary">Editar</button>
        </div>
      </div>
    </div>
  </section>
</section>

import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  faCalendar,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRightFromBracket,
  faBoxesPacking,
  faChartSimple,
  faClose,
  faCut,
  faDollarSign,
  faGear,
  faShop,
  faUserGroup,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { ShowSidebarService } from '../../services/other-services/show-sidebar/show-sidebar.service';
import { EmployeesService } from '../../services/api/employees/employees.service';
import { StoresService } from '../../services/api/stores/stores.service';
import { IStoreResponse } from '../../models/interfaces/responses/IStoreResponse';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  faCalendar = faCalendar;
  faUserTie = faUserTie;
  faUserGroup = faUserGroup;
  faBoxesPacking = faBoxesPacking;
  base64Image = '';
  private showModalService = inject(ShowSidebarService);
  private employeeService = inject(EmployeesService);
  private storeService = inject(StoresService);
  faShop = faShop;
  faChartSimple = faChartSimple;
  faClose = faClose;
  storeName = localStorage.getItem('storeName') ?? '';
  faArrowRightFromBracket = faArrowRightFromBracket;
  faGear = faGear;
  routeCurrent: string = '';
  showModal = false;
  store: IStoreResponse;
  base64Store: string = localStorage.getItem('storeImage') ?? '';
  skill: string = '';
  routesAdmin: { path: string; icon: IconDefinition; label: string }[] = [
    { path: '/scheduler', icon: faCalendar, label: 'Agendamento' },
    { path: '/customer', icon: faUserGroup, label: 'Clientes' },
    { path: '/product', icon: faBoxesPacking, label: 'Produtos' },
    { path: '/service', icon: faCut, label: 'Serviços' },
    { path: '/employee', icon: faUserTie, label: 'Funcionários' },
    { path: '/sale', icon: faDollarSign, label: 'Vendas' },
    { path: '/skill', icon: faUserTie, label: 'Funções' },
    {
      path: '/system-settings',
      icon: faGear,
      label: 'Configurações do sistema',
    },
  ];
  routesEmployees: { path: string; icon: IconDefinition; label: string }[] = [
    { path: '/scheduler', icon: faCalendar, label: 'Agendamento' },
  ];

  constructor(
    private route: Router,
    private cookie: CookieService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.skill = this.cookie.get('skill');
    this.route.events.subscribe((event) => {
      this.routeCurrent = this.route.url;
      console.log(this.routeCurrent);
    });
    this.employeeService
      .getEmployeeById(this.cookie.get('id'))
      .subscribe((employee) => {
        this.base64Image = employee.base64Image;
      });

    if (this.storeName === '' || this.base64Store === '') {
      this.storeService
        .getStoreId(this.cookie.get('storeId'))
        .subscribe((store) => {
          this.store = store;
          localStorage.setItem('storeName', this.store.fantasyName);
          localStorage.setItem('storeImage', this.store.base64Image ?? '');
          this.base64Store = this.store.base64Image ?? '';
          this.storeName = this.store.fantasyName;
          this.cd.detectChanges();
        });
    }
  }

  closeModal() {
    this.showModal = false;
    this.showModalService.setShowSidebar(this.showModal);
  }

  logout() {
    this.cookie.delete('authToken');
    this.cookie.delete('skill');
    this.cookie.delete('storeId');
    this.cookie.delete('id');
    this.showModalService.setShowSidebar(false);
    this.route.navigate(['/login']);
  }
}

import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../../../services/api/products/products.service';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../services/api/services/services.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-service-register',
  templateUrl: './modal-service-register.component.html',
  styleUrl: './modal-service-register.component.scss',
})
export class ModalServiceRegisterComponent {
  fb = inject(NonNullableFormBuilder);
  serviceForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    description: this.fb.control('', {
      validators: [Validators.required],
    }),
    price: this.fb.control(0, {
      validators: [Validators.required],
    }),
    storeId: this.fb.control(''),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private serviceService: ServicesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  onSubmit() {
    this.serviceService
      .postService(this.serviceForms.getRawValue())
      .subscribe((response) => {
        this.toastr.success('Serviço cadastrado com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success');
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.serviceForms.valid) {
      this.onSubmit();
    }
  }

  formatPrice(event: any): void {
    const valor = parseFloat(event.replace(/[^\d,]/g, '').replace(',', '.'));
    this.serviceForms
      .get('price')
      ?.setValue(Number(valor.toFixed(2)), { emitEvent: false });
  }
}

<div class="container d-flex flex-column justify-content-center">
  <section class="login__box">
    <section
      class="container d-flex justify-content-center align-items-center w-100 h-100"
    >
      <div class="card p-4 shadow-sm" style="width: 100%; max-width: 400px">
        <form [formGroup]="customerForms" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              id="email"
              formControlName="email"
              class="form-control form-control-lg"
              [ngClass]="{
                'is-invalid':
                  customerForms.controls.email.invalid &&
                  (customerForms.controls.email.dirty ||
                    customerForms.controls.email.touched)
              }"
            />
            <div
              *ngIf="
                customerForms.controls.email.invalid &&
                (customerForms.controls.email.dirty ||
                  customerForms.controls.email.touched)
              "
              class="invalid-feedback"
            >
              @if(customerForms.controls.email.errors?.['required']){
              <div class="error">Email é obrigatório</div>
              } @else if(customerForms.controls.email.errors?.['invalidEmail']){
              <div class="error">Email inválido</div>
              }
            </div>
          </div>
          <div class="d-grid mb-5">
            <button
              type="submit"
              [disabled]="customerForms.invalid || isLoading"
              class="btn btn-lg btn-primary"
            >
              @if(isLoading){
              <span
                class="spinner-border spinner-border-md"
                role="status"
                aria-hidden="true"
              ></span>
              Loading... } @else{ Enviar }
            </button>
          </div>
        </form>
      </div>
    </section>
  </section>
  @if(appointments!==[]){
    @for (appointment of appointments; track $index) {
      <app-card-customer-appointment [appointment]="appointment"/>
    }
  }
</div>

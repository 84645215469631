import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from '../../../services/api/products/products.service';
import { UpdateTablesService } from '../../../services/other-services/update-tables/update-tables.service';

@Component({
  selector: 'app-modal-product-register',
  templateUrl: './modal-product-register.component.html',
  styleUrl: './modal-product-register.component.scss',
})
export class ModalProductRegisterComponent {
  fb = inject(NonNullableFormBuilder);
  productForms = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    description: this.fb.control('', {
      validators: [Validators.required],
    }),
    price: this.fb.control(0, {
      validators: [Validators.required],
    }),
    storeId: this.fb.control(''),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private productService: ProductsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private updateTablesService: UpdateTablesService
  ) {}

  onSubmit() {
    this.productService
      .postProduct(this.productForms.getRawValue())
      .subscribe((response) => {
        this.toastr.success('Produto cadastrado com sucesso');
        this.cdr.detectChanges(); // Força a detecção de mudanças
        this.updateTablesService.updateTable();
        this.activeModal.dismiss('success');
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.productForms.valid) {
      this.onSubmit();
    }
  }

  formatPrice(event: any): void {
    const valor = parseFloat(event.replace(/[^\d,]/g, '').replace(',', '.'));
    this.productForms
      .get('price')
      ?.setValue(Number(valor.toFixed(2)), { emitEvent: false });
  }
}

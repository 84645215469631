import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ISkillRequest } from '../../models/interfaces/requests/ISkillRequest';
import { ISkillUpdate, SkillsService } from '../../services/api/skills/skills.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSkillRegisterComponent } from '../../components/modal-registers/modal-skill-register/modal-skill-register.component';
import { UpdateTablesService } from '../../services/other-services/update-tables/update-tables.service';
import { UpdateSkillComponent } from '../../components/updates/update-skill/update-skill.component';
import { ISkillResponse } from '../../models/interfaces/responses/ISkillResponse';
import { DeleteConfirmModalComponent } from '../../components/delete-confirm-modal/delete-confirm-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrl: './skill.component.scss',
})
export class SkillComponent {
  skills: ISkillResponse[] = [];
  private skillsService = inject(SkillsService);
  private cdr = inject(ChangeDetectorRef);
  private modalService = inject(NgbModal);
  private updateTablesService = inject(UpdateTablesService);
  skillToDelete: string = ''; 
  private toastr = inject(ToastrService);


  ngOnInit(): void {
    this.updateTablesService.updateTable$.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  loadData() {
    this.skillsService.getAllSkills().subscribe((skills: ISkillResponse[]) => {
      console.log(skills);
      this.skills = skills;
      this.cdr.detectChanges();
    });
  }

  openDeleteModal(skillId: string): void {
    this.skillToDelete = skillId;
    this.modalService
      .open(DeleteConfirmModalComponent)
      .result.then(
        (result) => {
          if (result === 'yes') {
            this.deleteSkill(this.skillToDelete);
          }
          this.skillToDelete = '';
        },
        (reason) => {
          this.skillToDelete = '';
        }
      );
  }

  deleteSkill(skillId: string): void {
    this.skillsService.deleteSkill(skillId).subscribe((data) => {
      this.toastr.success(data, 'Deletado!');
      window.location.reload();
    });
  }

  openRegisterModal(): void {
    const modalRef = this.modalService.open(ModalSkillRegisterComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
    });
    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Funcionario cadastrado com sucesso:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }

  openEditModal(skill: ISkillUpdate): void {
    const updateSkill: ISkillUpdate = {
      id: skill.id,
      name: skill.name,
    }
    const modalRef = this.modalService.open(UpdateSkillComponent);
    modalRef.componentInstance.skill = updateSkill;

    modalRef.result
      .then((result) => {
        if (result) {
          console.log('Cliente atualizado:', result);
        }
      })
      .catch((error) => {
        console.log('Modal dismissed with:', error);
      });
  }
}

import { AbstractControl, ValidationErrors } from "@angular/forms";

export function DateAppointmentValidator(control: AbstractControl): ValidationErrors | null {
    const date = new Date(control.value);
    if (!date) {
      return { 'invalidDate': true };
    }
    const now = new Date();
    if (date < now) {
      return { 'invalidDate': true };
    }
    return null;
  }
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environments';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  private API_URL = environment.API_URL;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private cookie: CookieService
  ) {}

  getAuthToken(employee: any) {
    const headers = new HttpHeaders({
      Authorization:
        'Basic ' + btoa(`${employee.username}:${employee.password}`),
    });
    return this.http.get(`${this.API_URL}/employees/login`, { headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  isLoggedIn(): boolean {
    const JWT_TOKEN = this.cookie.get('authToken');
    console.log('token',JWT_TOKEN);
    return JWT_TOKEN ? true : false;
  }
}

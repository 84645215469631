<div class="modal-header">
  <h4 class="modal-title">Editar cliente</h4>
</div>
<div class="modal-body">
  <form [formGroup]="customerForms">
    <div class="form-group mb-3">
      <label for="customerName">Nome</label>
      <input
        type="text"
        id="customerName"
        class="form-control form-control-lg"
        formControlName="name"
        name="name"
      />
    </div>
    <div class="form-group mb-3">
      <label for="customerEmail">Email</label>
      <input
        type="email"
        id="customerEmail"
        class="form-control form-control-lg"
        formControlName="email"
        name="email"
      />
    </div>
    <div class="form-group mb-3">
      <label for="customerPhone">Telefone</label>
      <input
        type="text"
        id="customerPhone"
        class="form-control form-control-lg"
        mask="(00)0 0000-0000"
        formControlName="phone"
        name="phone"
      />
    </div>
    <div class="form-group mb-3">
      <label for="customerCpf">CPF</label>
      <input
        type="text"
        id="customerCpf"
        mask="000.000.000-00"
        class="form-control form-control-lg"
        formControlName="cpf"
        name="cpf"
      />
    </div>
    <div class="form-group mb-3">
      <label for="typeahead-focus">Funcionario favorito</label>
      <ng-select
        [items]="employees"
        [searchable]="true"
        bindLabel="name"
        bindValue="id"
        placeholder="Selecione um funcionário"
        formControlName="favoriteEmployeeId"
        name="favoriteEmployeeId"
      />
    </div>
    <div class="form-group form-check form-switch mb-3">
      <input
        class="form-check-input"
        type="checkbox"
        id="customerLoyaltyPlan"
        formControlName="loyaltyPlan"
        name="loyaltyPlan"
      />
      <label class="form-check-label" for="customerLoyaltyPlan">
        Plano de Fidelidade
      </label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-lg"
    (click)="activeModal.dismiss('cancel')"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-lg"
    (click)="update(customer)"
  >
    Save changes
  </button>
</div>

<div class="container d-flex flex-column mt-5 gap-5 px-lg-4 px-md-1">
  <section class="table__container">
    <section class="header__box">
      <button
        type="button"
        class="btn btn-lg btn-primary"
        (click)="openRegisterModal()"
      >
        Adicionar função
      </button>
    </section>
    <div class="row">
      <div
        *ngFor="let skill of skills; index as i"
        class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
      >
        <div class="card h-100">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="card-title">Função</h5>
              <div class="d-flex gap-2">
                <button type="submit" class="btn btn-success btn-lg" (click)="openEditModal(skill)">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </div>
            </div>
            <p class="card-text">{{ skill.name | uppercase }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
